import { SvgIcon } from '@mui/material';

const BusyIcon = (props) => (
  <SvgIcon {...props}>
    <defs>
      <filter x="-15.4%" y="-37.2%" width="137.9%" height="174.5%" filterUnits="objectBoundingBox" id="filter-1">
        <feOffset dx="2" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
        <feColorMatrix values="0 0 0 0 0.501960784   0 0 0 0 0.505882353   0 0 0 0 0.564705882  0 0 0 0.24 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"/>
          <feMergeNode in="SourceGraphic"/>
        </feMerge>
      </filter>
    </defs>
    <g id="Calendar" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="calendarLocationWeek" transform="translate(-786.000000, -365.000000)" fill="#63DF98" fillRule="nonzero">
        <g id="Group-5" filter="url(#filter-1)" transform="translate(767.000000, 284.000000)">
          <g id="rounded-block-sign" transform="translate(32.000000, 94.000000)">
            <path d="M6.5,0 C2.925,0 0,2.925 0,6.5 C0,10.075 2.925,13 6.5,13 C10.075,13 13,10.075 13,6.5 C13,2.925 10.075,0 6.5,0 Z M1.3,6.5 C1.3,3.64 3.64,1.3 6.5,1.3 C7.67,1.3 8.775,1.69 9.685,2.405 L2.405,9.685 C1.69,8.775 1.3,7.67 1.3,6.5 Z M6.5,11.7 C5.33,11.7 4.225,11.31 3.315,10.595 L10.595,3.315 C11.31,4.225 11.7,5.33 11.7,6.5 C11.7,9.36 9.36,11.7 6.5,11.7 Z" id="Shape"/>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

BusyIcon.defaultProps = {
  viewBox: '13 13 13 13',
};

export default BusyIcon;
