import { SvgIcon } from '@mui/material';

const Clip = props => (
  <SvgIcon {...props}>
    <g>
      <g>
        <path d="m60.4,58.2c1.6-1.6 1.6-4.2 0-5.8s-4.2-1.6-5.8,0l-21.7,21.7c-3.7,3.7-5.7,8.5-5.7,13.7 0,5.2 2,10 5.7,13.7 7.6,7.6 19.9,7.6 27.4,0l49-49c5.1-5.1 7.9-11.9 7.9-19.1s-2.8-14-7.9-19.1c-10.6-10.6-27.7-10.6-38.3,0l-48.9,48.9c-6.6,6.6-10.2,15.3-10.2,24.6 0,9.3 3.6,18.1 10.2,24.6 6.6,6.6 15.3,10.2 24.6,10.2 9.3,0 18.1-3.6 24.6-10.2l21.7-21.7c1.6-1.6 1.6-4.2 0-5.8s-4.2-1.6-5.8,0l-21.7,21.7c-5,5-11.7,7.8-18.8,7.8s-13.8-2.8-18.8-7.8-7.8-11.7-7.8-18.8 2.8-13.8 7.8-18.8l48.9-48.9c3.6-3.6 8.3-5.5 13.3-5.5 5,0 9.8,2 13.3,5.5 3.6,3.6 5.5,8.3 5.5,13.3s-2,9.8-5.5,13.3l-49,49c-4.3,4.3-11.4,4.3-15.8,0-2.1-2.1-3.3-4.9-3.3-7.9 0-3 1.2-5.8 3.3-7.9l21.8-21.7z" data-original="#000000" class="active-path" data-old_color="#a6a7af" fill="#a6a7af"/>
      </g>
    </g>
  </SvgIcon>
);

Clip.defaultProps = {
  viewBox: "0 0 129 129",
};

export default Clip;
