import { SvgIcon } from '@mui/material';

const CalendarIcon = props => (
  <SvgIcon {...props}>
    <g>
      <path d="M452,40h-24V0h-40v40H124V0H84v40H60C26.916,40,0,66.916,0,100v352c0,33.084,26.916,60,60,60h392
        c33.084,0,60-26.916,60-60V100C512,66.916,485.084,40,452,40z M472,452c0,11.028-8.972,20-20,20H60c-11.028,0-20-8.972-20-20V188
        h432V452z M472,148H40v-48c0-11.028,8.972-20,20-20h24v40h40V80h264v40h40V80h24c11.028,0,20,8.972,20,20V148z"/>
    </g>
    <g>
      <rect x="76" y="230" width="40" height="40"/>
    </g>
    <g>
      <rect x="156" y="230" width="40" height="40"/>
    </g>
    <g>
      <rect x="236" y="230" width="40" height="40"/>
    </g>
    <g>
      <rect x="316" y="230" width="40" height="40"/>
    </g>
    <g>
      <rect x="396" y="230" width="40" height="40"/>
    </g>
    <g>
      <rect x="76" y="310" width="40" height="40"/>
    </g>
    <g>
      <rect x="156" y="310" width="40" height="40"/>
    </g>
    <g>
      <rect x="236" y="310" width="40" height="40"/>
    </g>
    <g>
      <rect x="316" y="310" width="40" height="40"/>
    </g>
    <g>
      <rect x="76" y="390" width="40" height="40"/>
    </g>
    <g>
      <rect x="156" y="390" width="40" height="40"/>
    </g>
    <g>
      <rect x="236" y="390" width="40" height="40"/>
    </g>
    <g>
      <rect x="316" y="390" width="40" height="40"/>
    </g>
    <g>
      <rect x="396" y="310" width="40" height="40"/>
    </g>
  </SvgIcon>
);

CalendarIcon.defaultProps = {
  viewBox: "0 0 512 512"
};

export default CalendarIcon;
