import { SvgIcon } from '@mui/material';

const BusinessmanIcon = props => (
  <SvgIcon {...props}>
    <g>
      <path d="M402.275,331.65c-52.2-19.9-83.4-40.3-83.4-40.3l-40.2,127.2l-5.5,17.2l-18-51c41.4-57.7-3.1-60.5-10.9-60.5l0,0h-0.1
        h-0.1h-0.1h-0.1l0,0c-7.7,0.1-52.2,2.8-10.8,60.5l-18,51l-5.5-17.2l-40.2-127.2c0,0-31.2,20.4-83.4,40.3
        c-57.5,20.9-53.9,68.2-55.1,156.8h213.1h0.5h213.1C456.175,399.85,459.675,352.55,402.275,331.65z"/>
      <path d="M142.375,228.25c-5.2,11.1-14,24.9-28.1,31.8c-4.6,2.3-3.6,9.2,1.5,10c15.4,2.4,37.5-0.3,58.1-15.9l0.3,0.2
        c52.3,30.9,120.8,23.8,165.8-21.1c53.4-53.4,53.4-139.9,0-193.2c-53.4-53.4-139.8-53.4-193.2,0c-51.4,51.4-53.3,133.4-5.8,187.1
        L142.375,228.25z M249.075,52.25c12.3,0.1,22.1,10.1,22,22.6c-0.1,12.3-10.1,22.4-22.1,22.3c-12.4-0.1-22.4-10.2-22.3-22.6
        C226.775,62.05,236.775,52.25,249.075,52.25z M205.775,112.05c0.3-2.4,1.8-4,5-4.6c1.7-0.3,3.4-0.4,5.1-0.4c6.8,0,13.7,0,20.5,0
        c7.2,0,14.5,0,21.7,0c5.2,0,8.3,2.5,8.4,7.7c0.1,4.3-0.6,8.7-1.4,13c-2.8,15.9-5.8,31.8-8.7,47.7c-1,5.3-2,10.5-2.7,15.8
        c-0.3,2.5,0.1,5.2,0.7,7.7c0.8,3.3,3.3,5.2,6.7,4.8c2.8-0.3,5.6-1.3,8.3-2.3c2.2-0.8,4.1-2.3,6.3-3.2c3.2-1.3,5.9,1,5.1,4.3
        c-0.5,1.9-1.7,3.9-3.1,5.3c-8.1,8.2-17.8,13.1-29.4,13.2c-5.4,0.1-10.9-0.1-16.2-0.9c-8.9-1.3-20.2-12.3-18.6-24.1
        c1.1-8,2.3-16.1,3.7-24c2.4-13.9,4.9-27.8,7.3-41.7c0.2-0.9,0.2-1.8,0.2-2.7c0-5.8-1.8-7.9-7.6-8.7c-2.5-0.3-5-0.5-7.3-1.4
        C206.775,116.55,205.575,114.25,205.775,112.05z"/>
    </g>
  </SvgIcon>
);

BusinessmanIcon.defaultProps = {
  viewBox: "0 0 488.45 488.45"
};

export default BusinessmanIcon;
