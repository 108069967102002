import { SvgIcon } from '@mui/material';

const InvSettingIcon = props => (
  <SvgIcon {...props}>
    <g>
      <path d="M448.7,187.65l-34.2-5.8c-3.5-11.6-8.2-22.9-14-33.7l20.1-28.2c8-11.2,6.7-26.3-3-36l-29.5-29.5
          c-5.3-5.3-12.3-8.2-19.8-8.2c-5.8,0-11.4,1.8-16.2,5.2l-28.3,20.1c-11.1-5.9-22.7-10.7-34.8-14.3l-5.7-33.8
           c-2.1-13.6-13.7-23.4-27.4-23.4h-41.8c-13.7,0-25.3,9.8-27.6,23.3l-5.9,34.5c-11.6,3.6-22.9,8.3-33.6,14.2l-27.9-19.9
           c-4.8-3.4-10.4-5.2-16.2-5.2c-7.5,0-14.5,2.9-19.8,8.2l-29.5,29.5c-9.7,9.7-11,24.8-3,36l20.3,28.6c-5.7,10.8-10.3,22.1-13.7,33.8
           l-33.8,5.7C9.8,190.85,0,202.45,0,216.15v41.8c0,13.7,9.8,25.3,23.3,27.6l34.5,5.9c3.6,11.6,8.3,22.9,14.2,33.6l-19.9,27.9
           c-8,11.2-6.7,26.3,3,36l29.5,29.5c5.3,5.3,12.3,8.2,19.8,8.2c5.8,0,11.4-1.8,16.2-5.2l28.6-20.3c10.4,5.5,21.4,10,32.6,13.4
           l5.7,34.1c2.3,13.5,13.9,23.3,27.6,23.3h41.8c13.7,0,25.3-9.8,27.6-23.3l5.8-34.2c11.6-3.5,22.9-8.2,33.7-14l28.2,20.1
           c4.8,3.4,10.4,5.2,16.2,5.2l0,0c7.5,0,14.5-2.9,19.8-8.2l29.5-29.5c9.7-9.7,11-24.8,3-36l-20.1-28.3c5.8-10.8,10.4-22,14-33.7
           l34.1-5.7c13.5-2.3,23.3-13.9,23.3-27.6v-41.8C472,201.55,462.2,189.95,448.7,187.65z M448,256.95c0,1.9-1.4,3.6-3.3,3.9l-41.6,7
           c-4.7,0.8-8.5,4.2-9.6,8.8c-3.8,14.7-9.6,28.8-17.4,41.9c-2.4,4.1-2.2,9.2,0.5,13.1l24.5,34.5c1.1,1.6,0.9,3.7-0.4,5.1l-29.5,29.5
           c-1,1-2.2,1.2-2.8,1.2c-0.6,0-1.4-0.1-2.3-0.7l-34.4-24.5c-3.9-2.8-9-3-13.1-0.6c-13.1,7.8-27.2,13.6-41.9,17.4
           c-4.6,1.2-8,4.9-8.8,9.6l-7.1,41.7c-0.3,1.9-2,3.3-3.9,3.3h-41.8c-1.9,0-3.6-1.4-3.9-3.3l-7-41.6c-0.8-4.7-4.2-8.5-8.8-9.6
           c-14.3-3.7-28.1-9.4-41-16.8c-1.9-1.1-4-1.6-6-1.6c-2.4,0-4.9,0.7-6.9,2.2l-34.7,24.6c-0.9,0.6-1.7,0.7-2.3,0.7
           c-0.6,0-1.8-0.2-2.8-1.2L72,371.95c-1.4-1.4-1.6-3.5-0.4-5.1l24.4-34.2c2.8-3.9,3-9,0.5-13.1c-7.8-13-13.8-27.1-17.6-41.8
           c-1.2-4.5-5-8-9.6-8.8l-42-7.1c-1.9-0.3-3.3-2-3.3-3.9v-41.8c0-1.9,1.4-3.6,3.3-3.9l41.4-6.9c4.7-0.8,8.5-4.3,9.7-8.9
           c3.7-14.7,9.4-28.8,17.1-42c2.4-4.1,2.1-9.1-0.6-13l-24.6-34.7c-1.1-1.6-0.9-3.7,0.4-5.1l29.4-29.6c1-1,2.2-1.2,2.8-1.2
           c0.6,0,1.4,0.1,2.3,0.7l34.2,24.4c3.9,2.8,9,3,13.1,0.5c13-7.8,27.1-13.8,41.8-17.6c4.5-1.2,8-5,8.8-9.6l7.1-42
           c0.3-1.9,2-3.3,3.9-3.3h41.8c1.9,0,3.6,1.4,3.9,3.3l6.9,41.4c0.8,4.7,4.3,8.5,8.9,9.7c15.1,3.8,29.5,9.7,42.9,17.6
           c4.1,2.4,9.2,2.2,13.1-0.5l34.5-24.5c0.9-0.6,1.7-0.7,2.3-0.7c0.6,0,1.8,0.2,2.8,1.2l29.5,29.5c1.4,1.4,1.6,3.5,0.4,5.1
           l-24.5,34.4c-2.8,3.9-3,9-0.6,13.1c7.8,13.1,13.6,27.2,17.4,41.9c1.2,4.6,4.9,8,9.6,8.8l41.7,7.1c1.9,0.3,3.3,2,3.3,3.9
            L448,256.95L448,256.95z"/>
      <path
        d="M252.8,223.85h-33.7c-13.7,0-24.9-11.2-24.9-24.9s11.2-24.9,24.9-24.9h59.4c6.6,0,12-5.4,12-12s-5.4-12-12-12H248v-23.2
           c0-6.6-5.4-12-12-12s-12,5.4-12,12v23.2h-4.8c-27,0-48.9,21.9-48.9,48.9s21.9,48.9,48.9,48.9h33.7c13.7,0,24.9,11.2,24.9,24.9
           c0,13.7-11.2,24.9-24.9,24.9h-60.5c-6.6,0-12,5.4-12,12c0,6.6,5.4,12,12,12H224v23.6c0,6.6,5.4,12,12,12s12-5.4,12-12v-23.6h5.2
           c0.1,0,0.2,0,0.3,0c26.7-0.3,48.3-22.2,48.3-48.9C301.8,245.75,279.8,223.85,252.8,223.85z"/>
    </g>
  </SvgIcon>
);

InvSettingIcon.defaultProps = {
  viewBox: "0 0 512 512"
}

export default InvSettingIcon;
