import { SvgIcon } from '@mui/material';

const MinorIcon = props => (
  <SvgIcon {...props}>
    <g>
      <g>
        <circle cx="1.916" cy="1.81" r="0.104"/>
        <circle cx="3.087" cy="1.81" r="0.104"/>
      </g>
      <path d="M2.5,0.667c-0.016,0-0.027-0.012-0.027-0.027c0-0.324,0.262-0.586,0.585-0.586c0.017,0,0.027,0.012,0.027,0.027    c0,0.017-0.012,0.029-0.027,0.029c-0.292,0-0.531,0.237-0.531,0.53C2.527,0.655,2.516,0.667,2.5,0.667z"/>
      <path d="M2.454,0.667c-0.015,0-0.028-0.012-0.028-0.027c0-0.135-0.11-0.245-0.245-0.245c-0.016,0-0.028-0.014-0.028-0.029    c0-0.015,0.012-0.027,0.028-0.027c0.166,0,0.301,0.134,0.301,0.301C2.482,0.655,2.469,0.667,2.454,0.667z"/>
      <g>
        <path d="M1.743,3.014c-0.007,0-0.014,0-0.021-0.007c-0.01-0.011-0.01-0.029,0-0.04c0.101-0.101,0.156-0.234,0.156-0.375     c0-0.141-0.055-0.275-0.156-0.374c-0.01-0.012-0.01-0.029,0-0.041c0.012-0.011,0.029-0.011,0.041,0     c0.11,0.111,0.171,0.258,0.171,0.415c0,0.157-0.062,0.304-0.171,0.415C1.758,3.014,1.75,3.014,1.743,3.014z"/>
        <path d="M3.258,3.014c-0.008,0-0.016,0-0.02-0.007C3.127,2.896,3.066,2.749,3.066,2.592c0-0.156,0.061-0.304,0.172-0.415     c0.01-0.011,0.027-0.011,0.039,0c0.012,0.012,0.012,0.029,0,0.041C3.177,2.317,3.121,2.45,3.121,2.592     c0,0.142,0.057,0.275,0.156,0.375c0.012,0.01,0.012,0.029,0,0.04C3.271,3.014,3.266,3.014,3.258,3.014z"/>
      </g>
      <path d="M2.5,3.059c-0.135,0-0.261-0.062-0.344-0.169c-0.009-0.012-0.007-0.03,0.005-0.04c0.012-0.01,0.03-0.008,0.04,0.006    C2.273,2.949,2.382,3.002,2.5,3.002c0.119,0,0.229-0.053,0.301-0.147C2.811,2.841,2.828,2.84,2.84,2.849    c0.012,0.009,0.014,0.027,0.004,0.04C2.762,2.996,2.637,3.059,2.5,3.059z"/>
      <g>
        <path d="M0.906,2.182c-0.016,0-0.028-0.012-0.028-0.027c0-0.029-0.023-0.051-0.052-0.051c-0.029,0-0.052,0.022-0.052,0.051     c0,0.015-0.013,0.027-0.028,0.027c-0.015,0-0.027-0.013-0.027-0.028c0-0.061,0.049-0.108,0.108-0.108     c0.059,0,0.108,0.047,0.108,0.108C0.935,2.169,0.922,2.182,0.906,2.182z"/>
        <path d="M4.254,2.182c-0.016,0-0.029-0.012-0.029-0.027c0-0.029-0.021-0.051-0.051-0.051c-0.027,0-0.053,0.022-0.053,0.051     c0,0.015-0.012,0.027-0.027,0.027c-0.015,0-0.027-0.013-0.027-0.028c0-0.061,0.049-0.108,0.106-0.108     c0.062,0,0.109,0.047,0.109,0.108C4.283,2.169,4.27,2.182,4.254,2.182z"/>
      </g>
      <g>
        <path d="M1.532,2.806c-0.007,0-0.015,0-0.021,0C1.461,2.8,1.416,2.774,1.386,2.736c-0.028-0.035-0.04-0.081-0.034-0.125       c0.006-0.04,0.028-0.078,0.061-0.103c0.031-0.021,0.069-0.03,0.105-0.024C1.552,2.49,1.582,2.51,1.599,2.537       c0.017,0.026,0.022,0.056,0.015,0.085c-0.007,0.026-0.023,0.047-0.046,0.06c-0.021,0.01-0.045,0.012-0.066,0.004       C1.483,2.677,1.469,2.66,1.465,2.642s0.002-0.038,0.016-0.049C1.497,2.582,1.52,2.582,1.533,2.595       c0.01,0.01,0.01,0.029,0,0.039c0.002,0,0.005-0.001,0.008-0.003C1.55,2.627,1.557,2.619,1.56,2.609       C1.563,2.595,1.561,2.58,1.552,2.568C1.543,2.552,1.527,2.543,1.508,2.539C1.487,2.536,1.465,2.542,1.446,2.554       c-0.02,0.015-0.034,0.039-0.037,0.065c-0.005,0.029,0.003,0.06,0.022,0.083c0.02,0.026,0.051,0.043,0.085,0.047       c0.038,0.004,0.075-0.008,0.105-0.032c0.031-0.024,0.052-0.064,0.056-0.105c0.004-0.045-0.01-0.091-0.04-0.126       c-0.03-0.037-0.076-0.06-0.126-0.064C1.458,2.417,1.405,2.436,1.364,2.469c-0.029,0.026-0.051,0.06-0.063,0.096       C1.296,2.58,1.28,2.587,1.265,2.583C1.25,2.578,1.243,2.562,1.248,2.546c0.015-0.046,0.043-0.088,0.08-0.12       c0.052-0.044,0.121-0.067,0.188-0.06C1.58,2.372,1.64,2.402,1.681,2.451C1.72,2.498,1.739,2.559,1.733,2.618       C1.728,2.674,1.699,2.727,1.656,2.762C1.621,2.791,1.576,2.806,1.532,2.806z"/>
        <path d="M3.469,2.806c-0.045,0-0.088-0.015-0.125-0.044c-0.043-0.035-0.07-0.088-0.076-0.145       C3.262,2.559,3.281,2.498,3.32,2.451c0.041-0.049,0.1-0.079,0.164-0.085C3.552,2.36,3.621,2.383,3.672,2.427       C3.709,2.458,3.736,2.5,3.754,2.546C3.756,2.562,3.75,2.578,3.736,2.583C3.721,2.587,3.705,2.58,3.699,2.564       c-0.012-0.036-0.033-0.07-0.064-0.096C3.596,2.436,3.541,2.417,3.49,2.422S3.395,2.449,3.363,2.487       C3.334,2.522,3.318,2.568,3.322,2.613c0.006,0.041,0.025,0.081,0.057,0.105C3.408,2.742,3.447,2.754,3.484,2.75       C3.518,2.746,3.55,2.729,3.568,2.703c0.02-0.024,0.027-0.055,0.024-0.083c-0.006-0.026-0.02-0.05-0.041-0.065       c-0.017-0.013-0.038-0.019-0.06-0.016c-0.02,0.005-0.035,0.016-0.043,0.029C3.439,2.58,3.438,2.595,3.441,2.609       c0.002,0.01,0.008,0.018,0.018,0.022c0.002,0.001,0.006,0.003,0.008,0.003c-0.01-0.01-0.01-0.027,0-0.039       C3.48,2.582,3.504,2.58,3.52,2.593C3.534,2.604,3.54,2.622,3.536,2.642S3.518,2.677,3.498,2.685       C3.477,2.694,3.453,2.692,3.432,2.682C3.41,2.669,3.393,2.648,3.387,2.623C3.379,2.593,3.385,2.563,3.4,2.537       C3.418,2.51,3.449,2.49,3.482,2.484c0.035-0.006,0.074,0.003,0.104,0.024C3.621,2.532,3.641,2.57,3.648,2.612       c0.006,0.044-0.008,0.089-0.035,0.125C3.584,2.774,3.539,2.8,3.49,2.806C3.484,2.806,3.477,2.806,3.469,2.806z"/>
      </g>
      <path d="M1.385,3.363H1.368c-0.008,0-0.014-0.006-0.014-0.014S1.36,3.334,1.368,3.334h0.017c0.008,0,0.014,0.008,0.014,0.016    C1.398,3.357,1.393,3.363,1.385,3.363z"/>
      <path d="M1.457,3.494c-0.014,0-0.027-0.005-0.038-0.016C1.111,3.212,0.916,2.847,0.864,2.446C0.852,2.448,0.839,2.449,0.827,2.449    c-0.163,0-0.295-0.132-0.295-0.295c0-0.164,0.132-0.296,0.295-0.296c0.022,0,0.043,0.003,0.064,0.007    C1.062,1.119,1.728,0.584,2.5,0.584c0.773,0,1.439,0.535,1.609,1.282C4.13,1.862,4.152,1.859,4.173,1.859    c0.164,0,0.295,0.132,0.295,0.296c0,0.163-0.131,0.295-0.295,0.295c-0.014,0-0.024-0.001-0.037-0.003    c-0.052,0.4-0.247,0.765-0.556,1.032C3.559,3.5,3.521,3.497,3.502,3.475c-0.02-0.023-0.018-0.06,0.006-0.078    c0.303-0.266,0.488-0.627,0.523-1.024l0.006-0.074l0.07,0.027c0.125,0.047,0.25-0.049,0.25-0.17c0-0.102-0.082-0.184-0.185-0.184    c-0.026,0-0.055,0.008-0.082,0.021L4.025,2.025L4.012,1.951C3.877,1.223,3.24,0.696,2.5,0.696c-0.741,0-1.376,0.527-1.512,1.255    L0.975,2.025L0.908,1.992C0.881,1.979,0.854,1.971,0.827,1.971c-0.101,0-0.183,0.082-0.183,0.184c0,0.122,0.125,0.217,0.249,0.17    l0.07-0.027l0.006,0.074C1.004,2.768,1.19,3.131,1.493,3.396c0.023,0.02,0.026,0.055,0.006,0.078    C1.487,3.486,1.472,3.494,1.457,3.494z"/>
      <path d="M2.5,5C1.854,5,1.328,4.475,1.328,3.827c0-0.14,0.026-0.276,0.075-0.409l0.029-0.075l0.061,0.054    C1.772,3.637,2.13,3.771,2.5,3.771c0.371,0,0.729-0.135,1.008-0.375l0.061-0.054l0.029,0.074c0.051,0.134,0.074,0.271,0.074,0.41    C3.672,4.475,3.146,5,2.5,5z M1.484,3.532C1.455,3.63,1.44,3.729,1.44,3.827c0,0.585,0.476,1.06,1.06,1.06s1.059-0.475,1.059-1.06    c0-0.099-0.014-0.197-0.041-0.295C3.227,3.76,2.869,3.883,2.5,3.883S1.773,3.76,1.484,3.532z"/>
      <g>
        <path d="M2.679,4.984c-0.002,0-0.002,0-0.004,0c-0.115-0.012-0.228-0.012-0.346,0C2.316,4.986,2.305,4.978,2.304,4.964      C2.303,4.952,2.312,4.939,2.325,4.939c0.121-0.013,0.237-0.013,0.356,0C2.693,4.94,2.702,4.952,2.702,4.964      C2.7,4.976,2.69,4.984,2.679,4.984z"/>
        <path d="M2.935,4.913c0,0-0.002-0.002-0.004-0.002c-0.108-0.025-0.252-0.053-0.43-0.053c-0.176,0-0.318,0.027-0.424,0.051      C2.065,4.913,2.052,4.904,2.05,4.892C2.047,4.88,2.054,4.866,2.067,4.864c0.107-0.025,0.254-0.053,0.434-0.053      c0.182,0,0.33,0.029,0.439,0.053C2.954,4.868,2.962,4.88,2.958,4.894C2.956,4.904,2.946,4.913,2.935,4.913z"/>
        <path d="M3.094,4.833c-0.002,0-0.004,0-0.006-0.002C2.89,4.779,2.72,4.741,2.501,4.741c-0.218,0-0.391,0.041-0.579,0.09      C1.91,4.833,1.897,4.825,1.893,4.813C1.89,4.801,1.898,4.79,1.91,4.784c0.192-0.05,0.367-0.091,0.591-0.091      c0.225,0,0.396,0.041,0.599,0.095c0.014,0.002,0.02,0.016,0.018,0.026C3.113,4.825,3.104,4.833,3.094,4.833z"/>
        <path d="M3.217,4.747c-0.002,0-0.004,0-0.006,0c-0.033-0.01-0.066-0.02-0.102-0.028C2.927,4.671,2.737,4.622,2.501,4.622      S2.076,4.671,1.893,4.719C1.862,4.728,1.831,4.735,1.799,4.743C1.787,4.747,1.774,4.741,1.771,4.727      C1.768,4.714,1.775,4.702,1.788,4.7C1.819,4.69,1.85,4.683,1.881,4.675c0.185-0.049,0.378-0.1,0.62-0.1s0.434,0.051,0.62,0.101      C3.154,4.684,3.188,4.692,3.223,4.7c0.012,0.004,0.02,0.017,0.018,0.027C3.236,4.739,3.227,4.747,3.217,4.747z"/>
        <path d="M1.685,4.652c-0.011,0-0.021-0.007-0.023-0.019C1.659,4.622,1.667,4.609,1.679,4.606      c0.069-0.016,0.137-0.033,0.202-0.05c0.186-0.049,0.378-0.099,0.62-0.099s0.436,0.05,0.622,0.099      C3.186,4.573,3.25,4.589,3.318,4.604C3.33,4.608,3.338,4.62,3.336,4.632C3.332,4.646,3.32,4.653,3.307,4.65      C3.24,4.636,3.174,4.618,3.109,4.602C2.927,4.554,2.737,4.503,2.501,4.503S2.076,4.554,1.893,4.602      C1.827,4.618,1.759,4.637,1.689,4.652C1.688,4.652,1.686,4.652,1.685,4.652z"/>
        <path d="M1.602,4.552c-0.011,0-0.021-0.008-0.023-0.02C1.577,4.52,1.585,4.508,1.597,4.505c0.099-0.019,0.194-0.043,0.285-0.067      C2.067,4.39,2.259,4.339,2.501,4.339s0.434,0.051,0.62,0.099c0.09,0.024,0.182,0.049,0.277,0.065      C3.412,4.505,3.42,4.519,3.418,4.53C3.416,4.544,3.404,4.552,3.391,4.551C3.293,4.53,3.199,4.507,3.109,4.484      c-0.183-0.05-0.373-0.099-0.608-0.099S2.076,4.435,1.893,4.483C1.801,4.508,1.707,4.532,1.606,4.55      C1.605,4.552,1.603,4.552,1.602,4.552z"/>
        <path d="M1.533,4.445c-0.012,0-0.021-0.009-0.024-0.021c-0.002-0.012,0.007-0.025,0.02-0.026C1.654,4.38,1.77,4.35,1.882,4.319      c0.185-0.049,0.377-0.099,0.619-0.099s0.434,0.05,0.62,0.099c0.111,0.029,0.225,0.059,0.346,0.077      c0.014,0.003,0.021,0.015,0.02,0.026c0,0.014-0.014,0.021-0.025,0.021C3.336,4.423,3.223,4.394,3.109,4.366      C2.927,4.317,2.737,4.268,2.501,4.268s-0.425,0.05-0.608,0.099C1.78,4.396,1.663,4.427,1.536,4.444      C1.535,4.445,1.533,4.445,1.533,4.445z"/>
        <path d="M1.476,4.335c-0.011,0-0.021-0.01-0.022-0.021C1.452,4.301,1.461,4.29,1.474,4.288c0.147-0.018,0.279-0.053,0.408-0.086      c0.186-0.049,0.377-0.1,0.619-0.1s0.434,0.051,0.62,0.1C3.248,4.234,3.379,4.27,3.523,4.288c0.012,0.002,0.02,0.013,0.02,0.025      c-0.002,0.012-0.016,0.021-0.027,0.02C3.369,4.314,3.238,4.28,3.109,4.247C2.927,4.2,2.737,4.149,2.501,4.149      S2.076,4.2,1.893,4.247C1.764,4.282,1.63,4.316,1.479,4.335C1.479,4.335,1.477,4.335,1.476,4.335z"/>
        <path d="M1.435,4.221c-0.012,0-0.022-0.009-0.023-0.021C1.41,4.188,1.419,4.175,1.432,4.173c0.165-0.014,0.309-0.053,0.449-0.09      c0.186-0.047,0.378-0.098,0.62-0.098s0.434,0.051,0.62,0.1C3.26,4.12,3.402,4.157,3.564,4.173      c0.012,0.002,0.02,0.013,0.02,0.025C3.582,4.212,3.572,4.221,3.559,4.219C3.393,4.204,3.25,4.165,3.109,4.13      C2.926,4.081,2.737,4.029,2.501,4.029S2.076,4.081,1.893,4.13C1.752,4.165,1.605,4.204,1.437,4.221      C1.436,4.221,1.435,4.221,1.435,4.221z"/>
        <path d="M3.604,4.105H3.602c-0.184-0.014-0.34-0.055-0.492-0.095C2.927,3.962,2.737,3.915,2.501,3.915S2.076,3.962,1.893,4.011      c-0.149,0.04-0.304,0.081-0.484,0.095c-0.013,0-0.024-0.01-0.025-0.021C1.382,4.07,1.392,4.06,1.405,4.058      c0.177-0.014,0.329-0.053,0.477-0.092c0.185-0.049,0.377-0.098,0.619-0.098s0.434,0.049,0.62,0.099      c0.15,0.038,0.305,0.079,0.484,0.092c0.014,0.002,0.021,0.013,0.02,0.026C3.625,4.096,3.615,4.105,3.604,4.105z"/>
        <path d="M3.615,3.989c-0.002,0-0.002,0-0.002,0C3.424,3.975,3.262,3.934,3.109,3.893C3.026,3.872,2.942,3.849,2.856,3.831      C2.843,3.829,2.835,3.816,2.839,3.805c0.002-0.013,0.014-0.021,0.027-0.019c0.088,0.02,0.173,0.041,0.255,0.062      c0.152,0.04,0.311,0.081,0.494,0.094c0.014,0.001,0.02,0.013,0.02,0.025C3.637,3.979,3.627,3.989,3.615,3.989z"/>
        <path d="M1.385,3.989c-0.012,0-0.022-0.01-0.023-0.021c0-0.014,0.009-0.024,0.021-0.025C1.57,3.929,1.729,3.888,1.882,3.847      c0.084-0.021,0.171-0.044,0.262-0.062c0.013,0,0.025,0.006,0.027,0.019S2.166,3.829,2.152,3.829      c-0.089,0.02-0.175,0.043-0.258,0.063C1.738,3.934,1.577,3.977,1.387,3.989C1.387,3.989,1.386,3.989,1.385,3.989z"/>
        <path d="M3.619,3.87c-0.002,0-0.004,0-0.004,0c-0.189-0.013-0.35-0.055-0.506-0.096l-0.035-0.01      C3.062,3.762,3.053,3.749,3.059,3.737c0.002-0.014,0.016-0.02,0.025-0.017l0.035,0.01C3.273,3.77,3.432,3.812,3.617,3.825      c0.014,0,0.023,0.01,0.021,0.023C3.641,3.86,3.631,3.87,3.619,3.87z"/>
        <path d="M1.385,3.87c-0.012,0-0.022-0.01-0.023-0.021c0-0.015,0.009-0.024,0.021-0.024c0.186-0.012,0.345-0.055,0.499-0.094      l0.041-0.013c0.013-0.003,0.025,0.005,0.029,0.017c0.003,0.014-0.005,0.026-0.017,0.029l-0.041,0.01      C1.738,3.815,1.577,3.857,1.387,3.87C1.387,3.87,1.386,3.87,1.385,3.87z"/>
        <path d="M3.615,3.751c-0.002,0-0.002,0-0.002,0C3.465,3.743,3.34,3.714,3.238,3.689c-0.012-0.004-0.021-0.017-0.02-0.026      c0.006-0.014,0.018-0.021,0.029-0.019C3.35,3.669,3.473,3.695,3.613,3.706c0.014,0,0.021,0.013,0.021,0.024      C3.637,3.743,3.627,3.751,3.615,3.751z"/>
        <path d="M1.385,3.753c-0.012,0-0.022-0.012-0.023-0.022c0-0.013,0.009-0.024,0.021-0.024c0.143-0.01,0.264-0.035,0.364-0.06      c0.012-0.003,0.025,0.005,0.028,0.017c0.003,0.013-0.004,0.026-0.017,0.029C1.657,3.717,1.533,3.743,1.387,3.751      C1.387,3.751,1.386,3.753,1.385,3.753z"/>
        <path d="M3.592,3.633c0,0-0.002,0-0.004-0.003C3.521,3.626,3.453,3.618,3.379,3.603c-0.014-0.002-0.021-0.014-0.02-0.027      c0.004-0.013,0.014-0.021,0.025-0.019C3.457,3.57,3.527,3.579,3.592,3.585c0.016,0.002,0.021,0.013,0.021,0.024      C3.613,3.622,3.604,3.633,3.592,3.633z"/>
        <path d="M1.406,3.633c-0.012,0-0.022-0.009-0.022-0.021C1.382,3.598,1.392,3.587,1.405,3.585      C1.472,3.581,1.54,3.573,1.611,3.559c0.013-0.003,0.025,0.006,0.027,0.019C1.64,3.589,1.633,3.601,1.62,3.604      C1.546,3.618,1.477,3.626,1.409,3.633C1.408,3.633,1.407,3.633,1.406,3.633z"/>
        <path d="M3.555,3.512c0,0,0,0-0.002,0C3.539,3.51,3.525,3.508,3.51,3.505C3.498,3.503,3.488,3.493,3.49,3.479      c0.002-0.013,0.014-0.021,0.025-0.02c0.016,0,0.025,0.002,0.043,0.004c0.012,0.002,0.02,0.013,0.02,0.025      C3.576,3.501,3.568,3.512,3.555,3.512z"/>
        <path d="M1.448,3.512c-0.011,0-0.021-0.011-0.023-0.021C1.424,3.477,1.434,3.466,1.446,3.464C1.461,3.462,1.474,3.46,1.488,3.46      C1.5,3.456,1.512,3.467,1.514,3.479c0.001,0.014-0.008,0.023-0.021,0.025C1.48,3.507,1.465,3.51,1.451,3.512      C1.451,3.512,1.45,3.512,1.448,3.512z"/>
      </g>
      <path fill="#000000" stroke="#1D71B8" strokeLinecap="round" strokeMiterlimit="10" d="M1.087,1.972"/>
      <path d="M1.372,1.451c-0.005,0-0.011-0.003-0.016-0.006C1.343,1.436,1.34,1.419,1.349,1.407c0.196-0.286,0.487-0.494,0.819-0.585    c0.015-0.003,0.03,0.004,0.034,0.02c0.004,0.015-0.004,0.03-0.019,0.035c-0.32,0.088-0.599,0.287-0.788,0.561    C1.39,1.447,1.381,1.451,1.372,1.451z"/>
      <path fill="#000000" stroke="#1D71B8" strokeLinecap="round" strokeMiterlimit="10" d="M1.238,1.644"/>
      <path d="M1.247,1.655c-0.004,0-0.008-0.001-0.013-0.003C1.22,1.644,1.215,1.627,1.222,1.614c0.007-0.013,0.014-0.026,0.021-0.04    C1.25,1.561,1.267,1.556,1.28,1.563c0.014,0.008,0.019,0.024,0.011,0.038C1.285,1.614,1.278,1.626,1.272,1.64    C1.267,1.648,1.256,1.655,1.247,1.655z"/>
    </g>
  </SvgIcon>
);

MinorIcon.defaultProps = {
  viewBox: "0 -0.5 5 6"
};

export default MinorIcon;
