import { SvgIcon } from '@mui/material';

const ReportingIcon = props => (
  <SvgIcon {...props}>
    <g id="report" transform="translate(0.000000, 1.000000)">
      <path d="M16.8265968,3.55377197 L12.7853522,0.142669678 C12.6769941,0.0512313926 12.5312087,0 12.3792357,0 L2.16233299,0 C0.970091882,0 0,0.833580025 0,1.8583374 L0,15.1416626 C0,16.1662903 0.969940975,17 2.16233299,17 L14.837667,17 C16.0299081,17 17,16.16642 17,15.1416626 L17,3.90914917 C17,3.77542876 16.9375205,3.64741514 16.8265968,3.55377197 Z M12.9587554,1.68531798 L15.0033734,3.41110229 L13.1430246,3.41110229 C13.0414577,3.41110229 12.9587554,3.34002686 12.9587554,3.25273896 L12.9587554,1.68531798 Z M14.837667,16.0039062 L2.16233299,16.0039062 C1.60922368,16.0039062 1.15903946,15.6171417 1.15903946,15.1416626 L1.15903946,1.8583374 C1.15903946,1.38285829 1.60907277,0.99609375 2.16233299,0.99609375 L11.7998668,0.99609375 L11.7998668,3.25273896 C11.7998668,3.88930512 12.4024768,4.40719604 13.1431755,4.40719604 L15.8409605,4.40719604 L15.8409605,15.1416626 C15.8409605,15.6171417 15.3909273,16.0039062 14.837667,16.0039062 Z" id="Shape"></path>
      <path d="M11.565133,7 L5.43486702,7 C5.19467094,7 5,7.22382813 5,7.5 C5,7.77617187 5.19467094,8 5.43486702,8 L11.565133,8 C11.8053291,8 12,7.77617187 12,7.5 C12,7.22382813 11.8053291,7 11.565133,7 Z" id="Path"></path>
      <path d="M5.44463314,10 L10.5553669,10 C10.8009572,10 11,9.77617187 11,9.5 C11,9.22382813 10.8009572,9 10.5553669,9 L5.44463314,9 C5.19904281,9 5,9.22382813 5,9.5 C5,9.77617187 5.19904281,10 5.44463314,10 Z" id="Path"></path>
      <path d="M11.5296012,11 L5.47039882,11 C5.21057698,11 5,11.2238281 5,11.5 C5,11.7761719 5.21057698,12 5.47039882,12 L11.5296012,12 C11.7893005,12 12,11.7761719 12,11.5 C12,11.2238281 11.7893005,11 11.5296012,11 Z" id="Path"></path>
    </g>
  </SvgIcon>
);

ReportingIcon.defaultProps = {
  viewBox: '0 0 17 17',
};

export default ReportingIcon;
