import { SvgIcon } from '@mui/material';

const GridIcon = props => (
  <SvgIcon {...props}>
    <defs>
      <path d="M6.22222222,0 L0.888888889,0 C0.397969111,0 0,0.397969111 0,0.888888889 L0,6.22222222 C0,6.713142 0.397969111,7.11111111 0.888888889,7.11111111 L6.22222222,7.11111111 C6.713142,7.11111111 7.11111111,6.713142 7.11111111,6.22222222 L7.11111111,0.888888889 C7.11111111,0.397969111 6.713142,0 6.22222222,0 Z M5.33333333,5.33333333 L1.77777778,5.33333333 L1.77777778,1.77777778 L5.33333333,1.77777778 L5.33333333,5.33333333 Z M15.1111111,0 L9.77777778,0 C9.286858,0 8.88888889,0.397969111 8.88888889,0.888888889 L8.88888889,6.22222222 C8.88888889,6.713142 9.286858,7.11111111 9.77777778,7.11111111 L15.1111111,7.11111111 C15.6020309,7.11111111 16,6.713142 16,6.22222222 L16,0.888888889 C16,0.397969111 15.6020309,0 15.1111111,0 Z M14.2222222,5.33333333 L10.6666667,5.33333333 L10.6666667,1.77777778 L14.2222222,1.77777778 L14.2222222,5.33333333 Z M0,15.1111111 C0,15.6020309 0.397969111,16 0.888888889,16 L6.22222222,16 C6.713142,16 7.11111111,15.6020309 7.11111111,15.1111111 L7.11111111,9.77777778 C7.11111111,9.286858 6.713142,8.88888889 6.22222222,8.88888889 L0.888888889,8.88888889 C0.397969111,8.88888889 0,9.286858 0,9.77777778 L0,15.1111111 Z M1.77777778,10.6666667 L5.33333333,10.6666667 L5.33333333,14.2222222 L1.77777778,14.2222222 L1.77777778,10.6666667 Z M8.88888889,15.1111111 C8.88888889,15.6020309 9.286858,16 9.77777778,16 L15.1111111,16 C15.6020309,16 16,15.6020309 16,15.1111111 L16,9.77777778 C16,9.286858 15.6020309,8.88888889 15.1111111,8.88888889 L9.77777778,8.88888889 C9.286858,8.88888889 8.88888889,9.286858 8.88888889,9.77777778 L8.88888889,15.1111111 Z M10.6666667,10.6666667 L14.2222222,10.6666667 L14.2222222,14.2222222 L10.6666667,14.2222222 L10.6666667,10.6666667 Z" id="path-3"></path>
    </defs>
    <g id="Staff" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Staff-List-View" transform="translate(-1209.000000, -135.000000)">
        <g id="Group-13" transform="translate(268.000000, 94.000000)">
          <g id="Group-3" transform="translate(821.000000, 29.000000)">
            <g id="Group-2" transform="translate(120.000000, 12.000000)">
              <g id="bx-grid-alt">
                <mask id="mask-4" fill="white">
                  <use xlinkHref="#path-3"></use>
                </mask>
                <g fillRule="nonzero"></g>
                <g id="color/light-gray" mask="url(#mask-4)" fill="#C6D3DD">
                  <g transform="translate(-2.666667, -2.666667)" id="Color">
                    <rect x="0" y="0" width="21" height="21"></rect>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

GridIcon.defaultProps = {
  viewBox: "0 0 16 16"
};

export default GridIcon;
