import { SvgIcon } from '@mui/material';

const MouseIcon = props => (
  <SvgIcon {...props}>
		<g>
			<path d="M95.36,97.539 C95.267,89.613 98.964,83.422 102.521,77.414 C107.247,69.457 112.134,61.225 108.736,49.918 C105.277,39.544 98.741,34.453 92.937,29.94 C85.146,23.876 79.52,19.498 82.435,2.904 L82.693,1.481 L74.293,0.001 L74.041,1.415 C70.21,23.095 79.508,30.31 87.699,36.672 C92.999,40.8 98.004,44.686 100.612,52.488 C102.888,60.105 99.49,65.825 95.193,73.061 C91.203,79.795 86.69,87.383 86.796,97.534 C44.245,97.59 0.77,99.34 0.77,250.574 C0.77,318.704 55.534,344.104 90.052,344.104 C124.577,344.104 179.335,318.704 179.335,250.574 C179.322,99.532 135.013,97.668 95.36,97.539 Z M94.312,224.387 L94.312,205.947 C99.56,204.932 103.518,200.284 103.518,194.844 L103.518,139.113 C103.518,133.664 99.56,129.022 94.312,128.007 L94.312,106.074 C129.428,106.149 162.298,107.542 169.372,206.6 C145.354,217.817 120.109,223.799 94.312,224.387 Z M94.985,194.844 C94.985,196.393 93.717,197.654 92.169,197.654 L87.912,197.654 C86.363,197.654 85.096,196.393 85.096,194.844 L85.096,139.113 C85.096,137.564 86.363,136.297 87.912,136.297 L92.169,136.297 C93.718,136.297 94.985,137.564 94.985,139.113 L94.985,194.844 Z M85.768,128.007 C80.511,129.022 76.563,133.664 76.563,139.113 L76.563,194.844 C76.563,200.283 80.511,204.932 85.768,205.947 L85.768,224.399 C46.512,223.475 17.914,210.378 10.696,206.733 C17.755,107.531 50.637,106.15 85.768,106.075 L85.768,128.007 Z" id="Shape" />
		</g>
  </SvgIcon>
);

MouseIcon.defaultProps = {
  viewBox: "0 0 180 345"
};

export default MouseIcon;
