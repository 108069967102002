import { SvgIcon } from '@mui/material';

const PackageIcon = ({ color = 'primary', ...props }) => (
  <SvgIcon {...props} color={color}>
    <path d="M271.72 77.8773C271.656 77.6533 271.536 77.4613 271.456 77.2373C271.314 76.7993 271.135 76.3733 270.92 75.9653C270.784 75.7413 270.6 75.5573 270.448 75.3413C270.209 74.9793 269.941 74.6373 269.648 74.3173C269.472 74.1413 269.248 74.0133 269.056 73.8533C268.741 73.5573 268.401 73.2903 268.04 73.0533H267.96L139.96 1.05334C137.526 -0.314656 134.554 -0.314656 132.12 1.05334L4.12 73.0533H4.04C3.679 73.2903 3.339 73.5573 3.024 73.8533C2.832 74.0133 2.608 74.1413 2.432 74.3173C2.139 74.6373 1.871 74.9793 1.632 75.3413C1.48 75.5573 1.296 75.7413 1.16 75.9653C0.945004 76.3733 0.766001 76.7983 0.624001 77.2373C0.544001 77.4613 0.424001 77.6533 0.360001 77.8773C0.147001 78.5573 0.026 79.2613 0 79.9733V221.917C0.003 224.772 1.527 227.409 4 228.837L132 302.893C132.481 303.154 132.987 303.363 133.512 303.517C133.664 303.565 133.8 303.653 133.944 303.693C135.291 304.056 136.709 304.056 138.056 303.693C138.2 303.653 138.336 303.565 138.488 303.517C139.013 303.363 139.519 303.154 140 302.893L268 228.837C270.473 227.409 271.997 224.772 272 221.917V79.9733C272 79.2653 271.906 78.5603 271.72 77.8773ZM136 17.1493L180.128 41.9493L68.448 104.773L24.32 79.9733L136 17.1493ZM128 282.109L16 217.309V93.6533L128 156.653V282.109ZM136 142.797L84.8 113.973L196.44 51.1733L247.68 79.9733L136 142.797ZM256 217.301L144 282.101V156.653L256 93.6533V217.301Z" />
    <path d="M240 199.973H224V215.973H240V199.973Z" />
    <path d="M208 215.973H192V231.973H208V215.973Z" />
    <path d="M176 231.973H160V247.973H176V231.973Z" />
  </SvgIcon>
);

PackageIcon.defaultProps = {
  viewBox: '0 0 273 304',
};

export default PackageIcon;
