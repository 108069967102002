import { SvgIcon } from '@mui/material';

const InvoiceIcon = props => (
  <SvgIcon {...props}>
    <g>
      <path d="M449.35,0h-430c-6.6,0-12,5.4-12,12s5.4,12,12,12h25.9v432.4c0,4.4,2.4,8.5,6.3,10.6s8.6,1.9,12.3-0.6l52.1-34.7
        l52.3,34.8c4,2.7,9.3,2.7,13.3,0l52.1-34.7l52.3,34.8c4,2.7,9.3,2.7,13.3,0l52.1-34.7l52.3,34.8c2,1.3,4.3,2,6.6,2
        c1.9,0,3.9-0.5,5.7-1.4c3.9-2.1,6.3-6.2,6.3-10.6l-0.2-432.4h27.3c6.6,0,12-5.4,12-12S455.95,0,449.35,0z M398.35,434l-40.3-26.8
        c-4-2.7-9.3-2.7-13.3,0l-52.1,34.8l-52.3-34.8c-4-2.7-9.3-2.7-13.3,0l-52.1,34.7l-52.3-34.7c-4-2.7-9.3-2.7-13.3,0l-40.1,26.7
        v-410h329L398.35,434z"/>
      <path d="M124.45,191.6h125.7c6.6,0,12-5.4,12-12s-5.4-12-12-12h-125.7c-6.6,0-12,5.4-12,12S117.75,191.6,124.45,191.6z"/>
      <path d="M124.45,233.3h94.2c6.6,0,12-5.4,12-12s-5.4-12-12-12h-94.2c-6.6,0-12,5.4-12,12S117.75,233.3,124.45,233.3z"/>
      <path d="M312.95,191.6h31.4c6.6,0,12-5.4,12-12s-5.4-12-12-12h-31.4c-6.6,0-12,5.4-12,12S306.25,191.6,312.95,191.6z"/>
      <path d="M312.95,233.3h31.4c6.6,0,12-5.4,12-12s-5.4-12-12-12h-31.4c-6.6,0-12,5.4-12,12S306.25,233.3,312.95,233.3z"/>
      <path d="M124.45,95.3h125.7c6.6,0,12-5.4,12-12c0-6.6-5.4-12-12-12h-125.7c-6.6,0-12,5.4-12,12
        C112.45,89.9,117.75,95.3,124.45,95.3z"/>
      <path d="M124.45,136.9h94.2c6.6,0,12-5.4,12-12s-5.4-12-12-12h-94.2c-6.6,0-12,5.4-12,12S117.75,136.9,124.45,136.9z"/>
      <path d="M312.95,95.3h31.4c6.6,0,12-5.4,12-12c0-6.6-5.4-12-12-12h-31.4c-6.6,0-12,5.4-12,12C300.95,89.9,306.25,95.3,312.95,95.3
        z"/>
      <path d="M312.95,136.9h31.4c6.6,0,12-5.4,12-12s-5.4-12-12-12h-31.4c-6.6,0-12,5.4-12,12S306.25,136.9,312.95,136.9z"/>
      <path d="M124.45,289.1h125.7c6.6,0,12-5.4,12-12s-5.4-12-12-12h-125.7c-6.6,0-12,5.4-12,12S117.75,289.1,124.45,289.1z"/>
      <path d="M124.45,330.7h94.2c6.6,0,12-5.4,12-12s-5.4-12-12-12h-94.2c-6.6,0-12,5.4-12,12S117.75,330.7,124.45,330.7z"/>
      <path d="M312.95,289.1h31.4c6.6,0,12-5.4,12-12s-5.4-12-12-12h-31.4c-6.6,0-12,5.4-12,12S306.25,289.1,312.95,289.1z"/>
      <path d="M312.95,330.7h31.4c6.6,0,12-5.4,12-12s-5.4-12-12-12h-31.4c-6.6,0-12,5.4-12,12S306.25,330.7,312.95,330.7z"/>
    </g>
  </SvgIcon>
);

InvoiceIcon.defaultProps = {
  viewBox: "0 0 468.7 468.7"
};

export default InvoiceIcon;
