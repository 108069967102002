import { SvgIcon } from '@mui/material';

const SocialMediaIcon = props => (
  <SvgIcon {...props}>
    <g>
      <rect x="160" y="40" width="160" height="16"/>
    </g>
    <g>
      <path d="M396,300c0-15.438-12.563-28-28-28h-16V160h-16v112h-20c-15.438,0-28,12.563-28,28c0,7.838,3.258,14.912,8.466,20
        c-5.208,5.088-8.466,12.162-8.466,20c0,5.393,1.605,10.39,4.259,14.668C282.689,359.125,276,368.768,276,380
        c0,4.311,1.061,8.349,2.809,12H216c0-24.461-12.258-46.109-30.953-59.125C203.125,325.984,216,308.469,216,288
        c0-4.418-3.578-8-8-8h-64v-80h-16v80c-13.234,0-24,10.766-24,24v176h16V304c0-4.41,3.586-8,8-8h70.984
        c-3.555,13.785-16.102,24-30.984,24h-24c-4.422,0-8,3.582-8,8s3.578,8,8,8c30.875,0,56,25.121,56,56v8c0,4.418,3.578,8,8,8h46.809
        c-1.748,3.651-2.809,7.689-2.809,12c0,15.438,12.563,28,28,28v32h16v-32h32h4c15.438,0,28-12.563,28-28
        c0-4.357-1.087-8.433-2.871-12.114C372.034,407.278,384,395.051,384,380c0-5.393-1.605-10.39-4.259-14.668
        C389.311,360.875,396,351.232,396,340c0-7.838-3.258-14.912-8.466-20C392.742,314.912,396,307.838,396,300z M332,432h-4h-40h-8
        c-6.617,0-12-5.383-12-12s5.383-12,12-12h24h28c6.617,0,12,5.383,12,12S338.617,432,332,432z M356,392h-24h-28
        c-6.617,0-12-5.383-12-12s5.383-12,12-12h12h40c6.617,0,12,5.383,12,12S362.617,392,356,392z M368,352h-12h-40
        c-6.617,0-12-5.383-12-12s5.383-12,12-12h52c6.617,0,12,5.383,12,12S374.617,352,368,352z M368,312h-52c-6.617,0-12-5.383-12-12
        s5.383-12,12-12h52c6.617,0,12,5.383,12,12S374.617,312,368,312z"/>
    </g>
    <g>
      <path d="M119.977,101.828c-0.164-2.297-1.305-4.414-3.141-5.805l-8.328-6.313C106.516,79.625,97.664,72,87.078,72
        c-11.945,0-21.688,9.711-21.844,21.707c-6.391-1.117-12.5-3.57-17.969-7.254l-2.789-1.883c-2.445-1.652-5.617-1.832-8.234-0.434
        C33.633,85.523,32,88.242,32,91.199v6.563c0,9.641,2.906,18.836,8.297,26.559l-5.984,6.055c-2.273,2.293-2.938,5.727-1.695,8.707
        C33.859,142.059,36.773,144,40,144h38.766c15.461,0,28.242-11.785,29.961-26.91l8.961-9.066
        C119.305,106.387,120.141,104.129,119.977,101.828z M95.234,107.977c-1.484,1.496-2.313,3.52-2.313,5.625
        c0,7.938-6.352,14.398-14.156,14.398H58.414c1.266-2.914,0.719-6.43-1.648-8.824c-3.734-3.781-6.406-8.539-7.758-13.668
        c7.633,3.219,15.836,4.891,24.227,4.891c4.422,0,8-3.582,8-8V94c0-3.309,2.625-6,5.844-6s5.844,2.691,5.844,6
        c0,2.504,1.172,4.863,3.164,6.375l3.813,2.887L95.234,107.977z"/>
    </g>
    <g>
      <path d="M264,136v-16h-24c-4.422,0-8,3.582-8,8v24h-16v16h16v32h16v-32h16v-16h-16v-16H264z"/>
    </g>
    <g>
      <path d="M288,88h-96c-13.234,0-24,10.766-24,24v96c0,13.234,10.766,24,24,24h96c13.234,0,24-10.766,24-24v-96
        C312,98.766,301.234,88,288,88z M296,208c0,4.41-3.586,8-8,8h-96c-4.414,0-8-3.59-8-8v-96c0-4.41,3.586-8,8-8h96
        c4.414,0,8,3.59,8,8V208z"/>
    </g>
    <g>
      <path d="M120,40H24C10.766,40,0,50.766,0,64v96c0,13.234,10.766,24,24,24h96c13.234,0,24-10.766,24-24V64
        C144,50.766,133.234,40,120,40z M128,160c0,4.41-3.586,8-8,8H24c-4.414,0-8-3.59-8-8V64c0-4.41,3.586-8,8-8h96c4.414,0,8,3.59,8,8
        V160z"/>
    </g>
    <g>
      <path d="M456,0h-96c-13.234,0-24,10.766-24,24v96c0,13.234,10.766,24,24,24h96c13.234,0,24-10.766,24-24V24
        C480,10.766,469.234,0,456,0z M464,120c0,4.41-3.586,8-8,8h-96c-4.414,0-8-3.59-8-8V80h16.809c3.717,18.234,19.876,32,39.191,32
        s35.474-13.766,39.191-32H464V120z M384,72c0-13.234,10.766-24,24-24s24,10.766,24,24s-10.766,24-24,24S384,85.234,384,72z
        M464,64h-16.809C443.474,45.766,427.315,32,408,32s-35.474,13.766-39.191,32H352V24c0-4.41,3.586-8,8-8h96c4.414,0,8,3.59,8,8V64
        z"/>
    </g>
    <g>
      <rect x="232" y="328" width="16" height="16"/>
    </g>
  </SvgIcon>
);

SocialMediaIcon.defaultProps = {
  viewBox: "0 0 480 480"
};

export default SocialMediaIcon;
