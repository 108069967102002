import { SvgIcon } from '@mui/material';

const TrashIcon = ({ strokeColor = '#FB4841', ...props }) => (
  <SvgIcon {...props}>
    <g id="Staff" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g id="Staff-List-View" transform="translate(-1256.000000, -235.000000)" stroke={strokeColor} strokeWidth="2">
        <g id="options" transform="translate(1089.000000, 222.000000)">
          <g id="buttons">
            <g id="trash" transform="translate(168.000000, 14.000000)">
              <path d="M2,6 L2,13 C2,14.105 2.895,15 4,15 L11,15 C12.105,15 13,14.105 13,13 L13,6" id="Shape"></path>
              <polyline id="Shape" points="5 3 5 0 10 0 10 3"></polyline>
              <rect id="Rectangle-path" x="0" y="3" width="15" height="3"></rect>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

TrashIcon.defaultProps = {
  viewBox: "0 0 17 17"
};

export default TrashIcon;
