import { SvgIcon } from '@mui/material';

const NotePadWithPenIcon = props => (
  <SvgIcon {...props}>
    <g>
      <path d="M17.573,0c-3.363,0-6.099,2.736-6.099,6.1v3.25c-2.483,0.383-4.731,1.671-6.316,3.685
           c-1.91,2.427-2.589,5.538-1.866,8.538l3.204,13.256c0.3,1.241,0.021,2.528-0.768,3.53c-0.792,1.007-1.977,1.582-3.468,1.582
           c-0.848,0-1.427,0.687-1.427,1.535c0,0.851,0.792,1.537,1.641,1.537c2.226,0,4.292-1.003,5.666-2.752
           c1.377-1.748,1.865-3.992,1.344-6.153L6.279,20.853c-0.504-2.079-0.033-4.236,1.289-5.917c0.998-1.269,2.373-2.127,3.905-2.473
            v16.725c0,0.85,0.688,1.536,1.536,1.536h9.214c0.849,0,1.536-0.687,1.536-1.536V6.1C23.761,2.736,21.024,0,17.573,0z
            M20.688,27.651h-6.144V12.29h6.144V27.651z M20.688,9.217h-6.144V6.1c0-1.668,1.359-3.027,3.116-3.027
            c1.668,0,3.027,1.359,3.027,3.027L20.688,9.217L20.688,9.217z M22.225,33.796h-9.214c-0.849,0-1.536,0.688-1.536,1.536v18.436
            c0,0.85,0.688,1.535,1.536,1.535h9.214c0.849,0,1.536-0.687,1.536-1.535V35.332C23.761,34.483,23.073,33.796,22.225,33.796z
            M20.688,52.23h-6.144V36.868h6.144V52.23z M22.225,58.375h-9.214c-0.531,0-1.026,0.275-1.308,0.729
            c-0.279,0.453-0.303,1.018-0.066,1.494l4.607,9.217c0.261,0.521,0.791,0.85,1.373,0.85s1.113-0.329,1.374-0.85l4.607-9.217
            c0.236-0.478,0.213-1.041-0.066-1.494C23.25,58.65,22.755,58.375,22.225,58.375z M17.617,65.693l-2.123-4.246h4.247
            L17.617,65.693z M68.294,9.217h-7.679V6.145c0-0.849-0.688-1.536-1.535-1.536s-1.536,0.688-1.536,1.536v3.072h-3.071V6.145
            c0-0.849-0.687-1.536-1.535-1.536c-0.85,0-1.535,0.688-1.535,1.536v3.072h-3.071V6.145c0-0.849-0.687-1.536-1.535-1.536
            c-0.851,0-1.536,0.688-1.536,1.536v3.072h-3.07V6.145c0-0.849-0.688-1.536-1.535-1.536c-0.85,0-1.536,0.688-1.536,1.536v3.072
            h-7.679c-0.849,0-1.535,0.688-1.535,1.537v49.157c0,0.85,0.687,1.536,1.535,1.536h36.856c0.85,0,1.536-0.688,1.536-1.536V10.754
            C69.831,9.904,69.143,9.217,68.294,9.217z M66.759,58.375H32.975V12.29h6.143v3.072c0,0.849,0.688,1.536,1.535,1.536
            c0.85,0,1.535-0.688,1.535-1.536V12.29h3.071v3.072c0,0.849,0.687,1.536,1.536,1.536s1.535-0.688,1.535-1.536V12.29h3.07v3.072
            c0,0.849,0.688,1.536,1.537,1.536c0.849,0,1.535-0.688,1.535-1.536V12.29h3.07v3.072c0,0.849,0.688,1.536,1.536,1.536
            s1.535-0.688,1.535-1.536V12.29h6.144L66.759,58.375L66.759,58.375z M57.544,27.651H42.188c-0.85,0-1.535,0.688-1.535,1.536
            c0,0.85,0.687,1.536,1.535,1.536h15.355c0.85,0,1.536-0.687,1.536-1.536C59.081,28.339,58.395,27.651,57.544,27.651z
            M57.544,33.796H42.188c-0.85,0-1.535,0.688-1.535,1.536c0,0.85,0.687,1.536,1.535,1.536h15.355c0.85,0,1.536-0.687,1.536-1.536
            C59.081,34.483,58.395,33.796,57.544,33.796z M57.544,39.941H42.188c-0.85,0-1.535,0.687-1.535,1.535
            c0,0.85,0.687,1.537,1.535,1.537h15.355c0.85,0,1.536-0.688,1.536-1.537C59.081,40.628,58.395,39.941,57.544,39.941z"/>
    </g>
  </SvgIcon>
);

NotePadWithPenIcon.defaultProps = {
  viewBox: "0 0 70 70"
}

export default NotePadWithPenIcon;
