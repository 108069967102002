import { SvgIcon } from '@mui/material';

const PrinterIcon = (props) => (
  <SvgIcon {...props}>
    <g id="Notes" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="client-page---SOAP-Reason-to-Visit-Pirint" transform="translate(-1268.000000, -57.000000)">
        <g id="Group-8" transform="translate(1258.624976, 48.166972)">
          <g id="printer">
            <g id="noun_Lock_2266489" transform="translate(21.000000, 21.000000)" fill="#F3980D" fillRule="nonzero">
              <g id="noun_print_2269742" transform="translate(0.713750, 0.000000)">
                <g id="printer">
                  <path d="M4.50893103,12.7433663 C4.56211355,12.796117 4.63410939,12.8255238 4.70901485,12.8250907 L11.4075955,12.8250907 C11.482501,12.8255238 11.5544968,12.796117 11.6076793,12.7433663 L4.50893103,12.7433663 Z" id="Path"></path>
                  <path d="M14.2003147,4.92318907 L14.2003147,3.43805991 C14.200346,3.32375225 14.1546883,3.21417365 14.073501,3.13370707 L11.0130641,0.123995603 L11.0130641,0.123995603 C10.9328592,0.0427511606 10.8228568,-0.0020646204 10.7087112,4.63225378e-17 L3.00971147,4.63225378e-17 C2.77625392,-1.42708111e-17 2.58699918,0.189254736 2.58699918,0.422712284 L2.58699918,4.85273703 C1.14485912,4.90134179 0.00070006812,6.08413802 -1.05471187e-15,7.52709675 L-1.05471187e-15,10.908795 C0.00106932802,12.2914263 1.05274731,13.446808 2.42918659,13.5775186 L2.42918659,15.9700701 C2.42918659,16.2035277 2.61844133,16.3927824 2.85189888,16.3927824 L13.7409673,16.3927824 C13.9744249,16.3927824 14.1636796,16.2035277 14.1636796,15.9700701 L14.1636796,13.5267931 C15.3887308,13.2535911 16.2600709,12.1667584 16.2603325,10.9116131 L16.2603325,7.52991483 C16.2610998,6.2884767 15.4083163,5.20937305 14.2003147,4.92318907 Z M11.1370597,1.4315856 L12.7461844,3.01252955 L11.1370597,3.01252955 L11.1370597,1.4315856 Z M3.43242375,0.845424569 L10.2916351,0.845424569 L10.2916351,3.43524183 C10.2916351,3.66869938 10.4808898,3.85795412 10.7143474,3.85795412 L13.3548901,3.85795412 L13.3548901,4.61038198 L3.43242375,4.61038198 L3.43242375,0.845424569 Z M13.318255,15.5473578 L3.27461116,15.5473578 L3.27461116,11.3371435 L13.318255,11.3371435 L13.318255,15.5473578 Z M15.414908,10.908795 C15.4137814,11.6967069 14.910423,12.3961937 14.1636796,12.6475516 L14.1636796,10.9144312 C14.1636796,10.6809736 13.9744249,10.4917189 13.7409673,10.4917189 L2.85189888,10.4917189 C2.61844133,10.4917189 2.42918659,10.6809736 2.42918659,10.9144312 L2.42918659,12.7264578 C1.5220032,12.6000234 0.846490178,11.824746 0.845424569,10.908795 L0.845424569,7.52709675 C0.846976384,6.51609085 1.66617191,5.69689533 2.6771778,5.69534351 L13.5803367,5.69534351 C14.5924429,5.69534232 15.4133533,6.51499169 15.414908,7.52709675 L15.414908,10.908795 Z" id="Shape"></path>
                  <path d="M11.4216859,14.1044999 C11.4216859,14.2601383 11.2955161,14.3863081 11.1398777,14.3863081 L4.70901485,14.3863081 C4.55337648,14.3863081 4.42720666,14.2601383 4.42720666,14.1044999 C4.42720666,13.9488615 4.55337648,13.8226917 4.70901485,13.8226917 L11.1398777,13.8226917 C11.2955161,13.8226917 11.4216859,13.9488615 11.4216859,14.1044999 Z" id="Path"></path>
                  <path d="M11.6894037,12.5432825 C11.6898368,12.618188 11.66043,12.6901838 11.6076793,12.7433663 C11.5544968,12.796117 11.482501,12.8255238 11.4075955,12.8250907 L4.70901485,12.8250907 C4.63410939,12.8255238 4.56211355,12.796117 4.50893103,12.7433663 C4.42764066,12.6627437 4.40320735,12.5409529 4.4471105,12.4352143 C4.49101366,12.3294757 4.59452602,12.2608085 4.70901485,12.2614743 L11.4075955,12.2614743 C11.4823357,12.2614743 11.5540147,12.2911648 11.606864,12.344014 C11.6597133,12.3968633 11.6894037,12.4685423 11.6894037,12.5432825 Z" id="Path"></path>
                  <path d="M14.2707667,7.57218606 C14.2707667,7.8056436 14.081512,7.99489834 13.8480544,7.99489834 L13.7071503,7.99489834 C13.6530262,7.99497396 13.5994118,7.98444256 13.5493378,7.96389944 C13.4992637,7.98444256 13.4456493,7.99497396 13.3915252,7.99489834 L13.2449849,7.99489834 C13.0115274,7.99489834 12.8222726,7.8056436 12.8222726,7.57218606 C12.8222726,7.33872851 13.0115274,7.14947377 13.2449849,7.14947377 L13.385889,7.14947377 C13.4400132,7.14939815 13.4936276,7.15992955 13.5437016,7.18047267 C13.5937756,7.15992955 13.64739,7.14939815 13.7015142,7.14947377 L13.8424183,7.14947377 C13.9555019,7.14796586 14.064474,7.19183491 14.1449712,7.27127287 C14.2254683,7.35071082 14.2707768,7.45909239 14.2707667,7.57218606 Z" id="Path"></path>
                  <path d="M11.6894037,12.5432825 C11.6898368,12.618188 11.66043,12.6901838 11.6076793,12.7433663 C11.5544968,12.796117 11.482501,12.8255238 11.4075955,12.8250907 L4.70901485,12.8250907 C4.63410939,12.8255238 4.56211355,12.796117 4.50893103,12.7433663 C4.42764066,12.6627437 4.40320735,12.5409529 4.4471105,12.4352143 C4.49101366,12.3294757 4.59452602,12.2608085 4.70901485,12.2614743 L11.4075955,12.2614743 C11.4823357,12.2614743 11.5540147,12.2911648 11.606864,12.344014 C11.6597133,12.3968633 11.6894037,12.4685423 11.6894037,12.5432825 Z" id="Path"></path>
                  <path d="M11.4216859,14.1044999 C11.4216859,14.2601383 11.2955161,14.3863081 11.1398777,14.3863081 L4.70901485,14.3863081 C4.55337648,14.3863081 4.42720666,14.2601383 4.42720666,14.1044999 C4.42720666,13.9488615 4.55337648,13.8226917 4.70901485,13.8226917 L11.1398777,13.8226917 C11.2955161,13.8226917 11.4216859,13.9488615 11.4216859,14.1044999 Z" id="Path"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

PrinterIcon.defaultProps = {
  viewBox: '11 11 19 19',
};

export default PrinterIcon;
