import { SvgIcon } from '@mui/material';

const AppointmentIcon = props => (
  <SvgIcon {...props}>
    <g>
      <path d="M279.602,52.201h-129.5c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h129.5c4.4,0,8-3.6,8-8
        C287.602,55.801,284.002,52.201,279.602,52.201z"/>
    </g>
    <g>
      <path d="M476.302,311.901c-10.2-19.4-25.9-35.2-45.3-45.3v-206.8c0.1-4.1-3.3-7.6-7.4-7.6c-0.1,0-0.3,0-0.4,0h-72.7
        c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h64.5v191.6c-10.8-3.6-22.1-5.5-33.5-5.4c-58.8,0-106.6,47.6-106.6,106.5
        c0,11.3,1.8,22.6,5.5,33.3h-264.4v-326h54c4.4,0,8-3.6,8-8c0-4.4-3.6-8-8-8h-62.3c-4.1-0.1-7.5,3-7.7,7.1c0,0.2,0,0.3,0,0.5v342.8
        c-0.1,4.1,3.1,7.5,7.2,7.6c0.2,0,0.3,0,0.5,0h279.5c27.1,52.2,91.5,72.5,143.7,45.4
        C483.102,428.501,503.402,364.101,476.302,311.901z M381.602,451.801c-50.1,0-90.7-40.6-90.7-90.7c0-50.1,40.6-90.7,90.7-90.7
        c50.1,0,90.7,40.6,90.7,90.7C472.302,411.101,431.702,451.701,381.602,451.801z"/>
    </g>
    <g>
      <path d="M319.002,20.701c-4.4,0-8,3.6-8,8v62.3c0,4.4,3.6,8,8,8c4.4,0,8-3.6,8-8v-62.3
        C327.002,24.301,323.402,20.701,319.002,20.701z"/>
    </g>
    <g>
      <path d="M112.002,20.701c-4.4,0-8,3.6-8,8v62.3c0,4.4,3.6,8,8,8c4.4,0,8-3.6,8-8v-62.3
        C120.002,24.301,116.402,20.701,112.002,20.701z"/>
    </g>
    <g>
      <path d="M371.302,150.201h-311.7c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h311.6c4.5,0,8.1-3.6,8.1-8
        C379.302,153.801,375.702,150.201,371.302,150.201z"/>
    </g>
    <g>
      <path d="M298.502,213.201h-238.9c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h238.9c4.5,0,8-3.6,8-8
        C306.502,216.801,302.902,213.201,298.502,213.201z"/>
    </g>
    <g>
      <path d="M194.602,275.201h-135c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h135c4.4,0,8-3.6,8-8
        C202.602,278.801,199.002,275.201,194.602,275.201z"/>
    </g>
    <g>
      <path d="M428.402,353.201h-38.4v-38.9c0-4.4-3.6-8-8-8c-4.4,0-8,3.6-8,8v38.9h-39.1c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h39.1v38.6
        c0,4.4,3.6,8,8,8c4.4,0,8-3.6,8-8v-38.6h38.4c4.4,0,8-3.6,8-8C436.402,356.801,432.802,353.201,428.402,353.201z"/>
    </g>
  </SvgIcon>
);

AppointmentIcon.defaultProps = {
  viewBox: "0 0 488.313 488.313"
};

export default AppointmentIcon;
