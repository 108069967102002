import { SvgIcon } from '@mui/material';

const EditIcon = props => (
  <SvgIcon {...props}>
    <defs>
      <path d="M13.0728889,0.260444444 C12.9063134,0.0935854591 12.6802183,-0.000178810492 12.4444444,-0.000178810492 C12.2086706,-0.000178810492 11.9825755,0.0935854591 11.816,0.260444444 L4.70488889,7.37155556 C4.59116779,7.48541893 4.51038563,7.62793954 4.47111111,7.784 L3.58222222,11.3395556 C3.50646244,11.6423788 3.59509975,11.9627488 3.81573643,12.1835676 C4.03637312,12.4043863 4.35666995,12.4932878 4.65955556,12.4177778 L8.21511111,11.5288889 C8.37155556,11.4897778 8.51466667,11.4088889 8.62844444,11.2951111 L15.7395556,4.184 C15.9064145,4.0174245 16.0001788,3.79132943 16.0001788,3.55555556 C16.0001788,3.31978169 15.9064145,3.09368661 15.7395556,2.92711111 L13.0728889,0.260444444 Z M7.54488889,9.864 L5.66577778,10.3333333 L6.136,8.45422222 L12.4444444,2.14577778 L13.8542222,3.55555556 L7.54488889,9.864 Z M0.888888889,16 L13.3333333,16 C13.8242531,16 14.2222222,15.6020309 14.2222222,15.1111111 L14.2222222,9.77777778 L12.4444444,9.77777778 L12.4444444,14.2222222 L1.77777778,14.2222222 L1.77777778,3.55555556 L6.22222222,3.55555556 L6.22222222,1.77777778 L0.888888889,1.77777778 C0.397969111,1.77777778 0,2.17574689 0,2.66666667 L0,15.1111111 C0,15.6020309 0.397969111,16 0.888888889,16 Z" id="path-1"></path>
    </defs>
    <g id="Staff" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Staff-List-View" transform="translate(-1186.000000, -235.000000)">
        <g id="options" transform="translate(1089.000000, 222.000000)">
          <g id="edit" transform="translate(97.000000, 13.000000)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <g id="bx-edit" fillRule="nonzero"></g>
            <g id="Color/Orange" mask="url(#mask-2)" fill="#F29200" fillRule="nonzero">
              <g transform="translate(-3.555556, -1.777778)" id="Rectangle">
                <rect x="0" y="0" width="21" height="21"></rect>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

EditIcon.defaultProps = {
  viewBox: "0 0 16 16"
};

export default EditIcon;
