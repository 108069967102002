import { SvgIcon } from '@mui/material';

const CommentIcon = props => (
  <SvgIcon {...props}>
    <g id="Staff" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g id="Staff-List-View" transform="translate(-1112.000000, -234.000000)" stroke="#F29200" strokeWidth="2">
        <g id="options" transform="translate(1089.000000, 222.000000)">
          <g id="comment-add" transform="translate(24.000000, 13.000000)">
            <path d="M12,1 L12,1 C12,0.44771525 11.5522847,3.08482111e-14 11,-4.4408921e-16 L2,0 C0.8954305,2.02906125e-16 -1.3527075e-16,0.8954305 0,2 L0,12 L3,9" id="Shape"></path>
            <path d="M16,4 L8,4 C6.8954305,4 6,4.8954305 6,6 L6,16 L9,13 L16,13 C17.1045695,13 18,12.1045695 18,11 L18,6 C18,4.8954305 17.1045695,4 16,4 Z" id="Shape" transform="translate(12.000000, 10.000000) scale(-1, 1) translate(-12.000000, -10.000000) "></path>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

CommentIcon.defaultProps = {
  viewBox: "0 0 20 18"
};

export default CommentIcon;
