import { CloseRounded as CloseIconOriginal } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
}));

const CloseIcon = (props) => {
  const {classes} = useStyles();
  return <CloseIconOriginal className={classes.root} {...props} />;
};

CloseIcon.defaultProps = {
  viewBox: '4 4 16 16',
};

export default CloseIcon;
