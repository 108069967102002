import { SvgIcon } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const styles = theme => ({
  reverse: {
    transform: "rotate(180deg)",
  },
});

const ArrowIcon = ({ reverse, classes, ...props }) => (
  <SvgIcon className={reverse && classes.reverse} {...props}>
    <defs>
      <filter x="-1.0%" y="-2.0%" width="102.1%" height="104.0%" filterUnits="objectBoundingBox" id="filter-1">
        <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0.960784314   0 0 0 0 0.650980392   0 0 0 0 0.137254902  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
        </feMerge>
      </filter>
      <path d="M317.987,417.06325 C317.99,417.02925 317.994,416.99725 317.993,416.96325 C317.989,416.85625 317.97,416.75025 317.931,416.64725 C317.923,416.62025 317.923,416.61825 317.923,416.61725 C317.877,416.50525 317.807,416.40925 317.727,416.32325 C317.718,416.31425 317.715,416.30225 317.707,416.29325 L313.707,412.29325 C313.316,411.90225 312.683,411.90225 312.293,412.29325 C311.902,412.68425 311.902,413.31625 312.293,413.70725 L314.585,416.00025 L295,416.00025 C294.447,416.00025 294,416.44825 294,417.00025 C294,417.55225 294.447,418.00025 295,418.00025 L314.585,418.00025 L312.293,420.29325 C311.902,420.68425 311.902,421.31625 312.293,421.70725 C312.488,421.90225 312.744,422.00025 313,422.00025 C313.255,422.00025 313.511,421.90225 313.707,421.70725 L317.707,417.70725 C317.793,417.62025 317.855,417.51825 317.904,417.41025 C317.918,417.37725 317.927,417.34625 317.939,417.31225 C317.965,417.23125 317.982,417.14925 317.987,417.06325 Z" id="path-2"></path>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Login-Forgot-Password" transform="translate(-980.000000, -635.000000)">
        <g id="Group-2" filter="url(#filter-1)" transform="translate(139.000000, 160.000000)">
          <g id="Group" transform="translate(551.000000, 65.000000)">
            <mask id="mask-3" fill="white">
              <use xlinkHref="#path-2"></use>
            </mask>
            <use id="Login" fill="#FFFFFF" xlinkHref="#path-2"></use>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

ArrowIcon.defaultProps = {
  viewBox: "0 0 32 18"
};

export default withStyles(ArrowIcon, styles);
