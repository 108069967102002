import { SvgIcon } from '@mui/material';

const SignatureIcon = props => (
  <SvgIcon {...props}>
    <g>
			<path d="M66.883,211.547l95.048-20.062c2.637-0.557,4.773-2.486,5.595-5.053l16.319-51.008
				c4.596-4.596,34.372-34.371,39.247-39.247c2.929-2.929,2.929-7.678,0-10.606l-40.155-40.155c-2.929-2.929-7.678-2.929-10.607,0
				c-4.85,4.85-34.284,34.285-38.868,38.869l-51.581,16.502c-2.567,0.822-4.497,2.957-5.053,5.595L56.766,201.43
				C53.93,207.71,60.528,214.418,66.883,211.547z M177.633,61.325l29.549,29.549l-9.471,9.471l-29.549-29.549L177.633,61.325z
				 M157.556,81.403l29.549,29.549l-9.471,9.471l-29.548-29.548L157.556,81.403z M90.604,113.745l44.805-14.334l33.493,33.493
				l-14.335,44.805l-67.879,14.328l39.817-39.817c2.929-2.929,2.929-7.678,0-10.607c-2.929-2.929-7.678-2.929-10.606,0
				l-39.518,39.518L90.604,113.745z"/>
			<path d="M347.503,296.784H147.279c-10.66,0-16.729-12.15-10.29-20.671c13.88-18.37,0.76-44.714-22.258-44.714H7.5
				c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h107.232c10.659,0,16.729,12.149,10.29,20.671
				c-13.879,18.369-0.761,44.714,22.258,44.714h200.224c4.142,0,7.5-3.358,7.5-7.5C355.004,300.142,351.645,296.784,347.503,296.784
				z"/>
		</g>
  </SvgIcon>
);

SignatureIcon.defaultProps = {
  viewBox: "0 0 355.004 355.004"
};

export default SignatureIcon;
