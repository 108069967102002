import { SvgIcon } from '@mui/material';

const FolderIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M18.125,2.99999996 L10.625,2.99999996 L8.12499999,0.499999962 L1.87499996,0.499999962 C0.839453079,0.499999962
      -5.00000041e-08,1.33945309 -5.00000041e-08,2.37499997 L-5.00000041e-08,13.625 C-5.00000041e-08,14.6605469
       0.839453079,15.5 1.87499996,15.5 L18.125,15.5 C19.1605469,15.5 19.9999999,14.6605469 19.9999999,13.625 L19.9999999,4.87499998
        C19.9999999,3.8394531 19.1605469,2.99999996 18.125,2.99999996 Z"
      />
  </SvgIcon>
);

FolderIcon.defaultProps = {
  viewBox: "0 0 20 18"
};

export default FolderIcon;
