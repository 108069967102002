import { SvgIcon } from '@mui/material';

const FileIcon = props => (
  <SvgIcon {...props}>
    <g>
      <rect x="117.333" y="64" width="106.667" height="21.333"/>
    </g>
    <g>
      <rect x="117.333" y="106.667" width="21.333" height="21.333"/>
    </g>
    <g>
      <rect x="160" y="106.667" width="21.333" height="21.333"/>
    </g>
    <g>
      <path d="M455.541,88.459L370.208,3.125c-2-2-4.713-3.125-7.541-3.125H85.333c-17.673,0-32,14.327-32,32v448
        c0,17.673,14.327,32,32,32h341.333c17.673,0,32-14.327,32-32V96C458.666,93.171,457.542,90.459,455.541,88.459z M437.333,480
        c0,5.891-4.776,10.667-10.667,10.667H85.333c-5.891,0-10.667-4.776-10.667-10.667V32c0-5.891,4.776-10.667,10.667-10.667
        l272.917,0l79.083,79.083V480z"/>
    </g>
    <g>
      <path d="M384,85.333c-5.891,0-10.667-4.776-10.667-10.667v-64H352v64c0,17.673,14.327,32,32,32h21.333V85.333H384z"/>
    </g>
    <g>
      <rect x="373.333" y="362.667" width="21.333" height="21.333"/>
    </g>
    <g>
      <rect x="330.667" y="362.667" width="21.333" height="21.333"/>
    </g>
    <g>
      <rect x="117.333" y="362.667" width="170.667" height="21.333"/>
    </g>
    <g>
      <rect x="117.333" y="416" width="277.333" height="21.333"/>
    </g>
    <g>
      <path d="M373.333,160H138.667c-11.782,0-21.333,9.551-21.333,21.333v128c0,11.782,9.551,21.333,21.333,21.333h234.667
        c11.782,0,21.333-9.551,21.333-21.333v-128C394.667,169.551,385.115,160,373.333,160z M373.333,309.333H138.667v-128h234.667
        V309.333z"/>
    </g>
  </SvgIcon>
);

FileIcon.defaultProps = {
  viewBox: "0 0 512 512"
};

export default FileIcon;
