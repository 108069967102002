import { SvgIcon } from '@mui/material';

const ListIcon = props => (
  <SvgIcon {...props}>
    <defs>
      <path d="M0,0 L16,0 L16,1.77777778 L0,1.77777778 L0,0 Z M0,3.55555556 L16,3.55555556 L16,5.33333333 L0,5.33333333 L0,3.55555556 Z M0,7.11111111 L16,7.11111111 L16,8.88888889 L0,8.88888889 L0,7.11111111 Z M0,10.6666667 L16,10.6666667 L16,12.4444444 L0,12.4444444 L0,10.6666667 Z" id="path-4"></path>
    </defs>
    <g id="Staff" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Staff-List-View" transform="translate(-1105.000000, -137.000000)">
        <g id="Group-13" transform="translate(268.000000, 94.000000)">
          <g id="Group-3" transform="translate(821.000000, 29.000000)">
            <g id="Group" transform="translate(16.000000, 14.000000)">
              <g id="bx-list">
                <mask id="mask-5" fill="white">
                  <use xlinkHref="#path-4"></use>
                </mask>
                <g fillRule="nonzero"></g>
                <g id="color/light-gray" mask="url(#mask-5)" fill="#C6D3DD">
                  <g transform="translate(-2.666667, -4.444444)" id="Color">
                    <rect x="0" y="0" width="21" height="21"></rect>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

ListIcon.defaultProps = {
  viewBox: "0 0 16 13"
};

export default ListIcon;
