import { SvgIcon } from '@mui/material';

const TickIcon = ({ disabled, strokeColor = '#f29200', ...props }) => (
  <SvgIcon {...props}>
    <g id="Staff" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g id="Staff-List-View" transform="translate(-309.000000, -366.000000)" stroke={strokeColor} strokeWidth="2">
        <g id="table" transform="translate(303.000000, 314.000000)">
          <g id="rows" transform="translate(0.000000, 38.000000)">
            <g id="row">
              <polyline id="Path-8" points="7 19.5615528 11.1231056 24.1231056 17 15"></polyline>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

TickIcon.defaultProps = {
  viewBox: "0 0 12 12"
};

export default TickIcon;
