import { SvgIcon } from '@mui/material';

const TranslationIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(0 -540.36)">
			<path d="M213.769,651.94L213.769,651.94c-5.499,0-9.999,4.501-9.999,10.003v51.216h-10.498c0-0.2,0-0.5,0-0.7c0-0.1,0-0.2,0-0.2
				c-3.5-34.911-36.995-63.02-71.79-60.219c-5.499,0.4-9.599,5.302-9.199,10.803c0.4,5.502,5.299,9.603,10.798,9.203
				c23.597-1.901,47.193,17.606,50.093,41.213H71.09c-2.8,0-5.499,1.2-7.399,3.301c-1.9,2.101-2.8,4.902-2.5,7.702
				c1.7,17.005,10.698,33.211,24.596,44.614c12.098,9.803,26.796,15.205,41.294,15.205c2,0,4.099-0.1,6.099-0.3
				c27.996-3.001,51.193-24.308,58.092-50.416h12.498v40.513c0,5.502,4.499,10.003,9.999,10.003c5.499,0,9.999-4.501,9.999-10.003
				V662.244C223.768,656.442,219.269,651.94,213.769,651.94z M131.081,763.776c-10.998,1.2-22.897-2.601-32.695-10.503
				c-6.699-5.402-11.798-12.404-14.698-20.006h86.688C164.176,749.271,148.979,761.875,131.081,763.776z"/>
			<path d="M405.742,869.409l-50.293-99.432c-1.7-3.401-5.199-5.502-8.899-5.502c-3.799,0-7.199,2.101-8.899,5.502L286.559,871.71
				c-2.5,4.902-0.5,10.903,4.399,13.404c1.4,0.7,3,1.1,4.499,1.1c3.699,0,7.199-2.001,8.899-5.502l17.597-35.011h49.393
				l16.598,32.81c2.5,4.902,8.499,6.902,13.398,4.401C406.242,880.313,408.241,874.311,405.742,869.409L405.742,869.409z
				 M331.952,825.595l14.598-29.009l14.698,29.009H331.952z"/>
			<path d="M486.33,812.991c-4.799-31.31-20.097-60.319-43.094-81.826c-43.494-40.613-106.985-49.516-159.377-23.808
				c-7.899-71.023-68.09-126.54-141.08-126.54c-38.094,0-73.889,14.705-100.885,41.513C14.898,649.139,0,684.951,0,723.163
				c0,38.112,14.898,73.924,41.894,100.832c26.896,26.809,62.791,41.513,100.885,41.513c22.097,0,43.094-5.102,61.791-14.205
				c6.399,58.118,48.093,107.334,105.685,122.539h0.1c11.498,2.801,22.997,4.201,34.395,4.201
				c51.293,0,99.986-27.709,125.482-74.124C485.53,876.412,491.129,844.101,486.33,812.991z M142.78,845.502
				c-67.59,0-122.682-54.817-122.682-122.339c0-67.421,55.092-122.339,122.682-122.339c67.29,0,121.982,54.917,121.982,122.339
				S210.07,845.502,142.78,845.502z M452.835,894.317c-26.796,48.816-83.288,73.523-137.48,60.119
				c-52.492-13.904-89.587-60.619-91.487-114.436c35.195-24.608,58.692-64.721,60.791-110.335
				c46.493-27.209,105.385-20.907,144.979,16.205C470.432,783.882,479.931,844.902,452.835,894.317z"/>
			<path d="M193.172,966.24v-0.1c-0.1-0.4-0.2-0.8-0.3-1.1c0,0,0,0,0-0.1c-0.1-0.3-0.2-0.7-0.4-1c0-0.1-0.1-0.2-0.2-0.3
				c-0.1-0.2-0.3-0.5-0.4-0.7c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.3-0.4-0.6-0.6-0.9c-0.2-0.3-0.5-0.6-0.8-0.9
				c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.5-0.4-0.7-0.6c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.2-0.5-0.3-0.7-0.5
				c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.2-0.7-0.4-1-0.5l-51.093-20.607c-5.099-2.101-10.898,0.4-12.998,5.502s0.4,10.903,5.499,13.004
				l34.195,13.804c-18.797,10.103-41.494,10.903-61.191,1.5h-0.1c-24.896-11.504-40.594-36.912-39.994-64.921
				c0.1-5.502-4.199-10.103-9.799-10.203c-5.499-0.1-10.099,4.201-10.199,9.803c-0.9,35.911,19.397,68.622,51.493,83.527
				c11.998,5.702,24.796,8.503,37.595,8.503c10.898,0,21.797-2.101,32.095-6.102l-9.099,22.207c-2.1,5.102,0.4,11.004,5.499,13.004
				c1.2,0.5,2.5,0.7,3.799,0.7c3.899,0,7.699-2.301,9.299-6.202l20.597-50.516c0-0.1,0.1-0.2,0.1-0.3c-0.1-0.1-0.1-0.2,0-0.3
				c0.1-0.3,0.2-0.6,0.2-0.8c0-0.1,0-0.2,0.1-0.3c0.1-0.4,0.1-0.8,0.2-1.2c0-0.4,0-0.7,0-1.1c0-0.1,0-0.2,0-0.3
				c0-0.3-0.1-0.6-0.1-0.9C193.172,966.34,193.172,966.34,193.172,966.24z"/>
			<path d="M295.458,602.625v0.1c0.1,0.4,0.2,0.8,0.3,1.1c0,0,0,0,0,0.1c0.1,0.3,0.2,0.7,0.4,1c0,0.1,0.1,0.2,0.2,0.3
				c0.1,0.2,0.3,0.5,0.4,0.7c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.3,0.4,0.6,0.6,0.9c0.2,0.3,0.5,0.6,0.8,0.9c0.1,0.1,0.1,0.1,0.2,0.2
				c0.2,0.2,0.5,0.4,0.7,0.6c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.2,0.5,0.3,0.7,0.5c0.1,0.1,0.2,0.1,0.3,0.2c0.3,0.2,0.7,0.4,1,0.5
				l51.093,20.607c5.099,2.101,10.898-0.4,12.998-5.502c2.1-5.102-0.4-10.903-5.499-13.004l-34.195-13.804
				c18.797-10.103,41.494-10.903,61.191-1.5h0.1c24.896,11.504,40.594,36.912,39.994,64.921c-0.1,5.502,4.199,10.103,9.799,10.203
				c0.1,0,0.2,0,0.2,0c5.399,0,9.899-4.301,9.999-9.803c0.8-36.011-19.397-68.822-51.593-83.627l0,0
				c-22.297-10.603-47.393-11.104-69.59-2.401l9.099-22.207c2.1-5.102-0.4-11.003-5.499-13.004
				c-5.099-2.101-10.998,0.4-12.998,5.502l-20.597,50.516c0,0.1-0.1,0.2-0.1,0.3s-0.1,0.3-0.1,0.4c-0.1,0.3-0.2,0.6-0.2,0.8
				c0,0.1,0,0.2-0.1,0.3c-0.1,0.4-0.1,0.8-0.2,1.2c0,0.4,0,0.7,0,1.1c0,0.1,0,0.2,0,0.3c0,0.3,0.1,0.6,0.1,0.9
				C295.458,602.525,295.458,602.525,295.458,602.625z"/>
		</g>
  </SvgIcon>
);

TranslationIcon.defaultProps = {
  viewBox: "0 0 488 488"
};

export default TranslationIcon;
