import { SvgIcon } from '@mui/material';

const DocumentSheetsIcon = props => (
  <SvgIcon {...props}>
    <g>
      <path d="M401.625,0H191.25C170.212,0,153,17.212,153,38.25c-21.038,0-38.25,17.212-38.25,38.25l0,0l0,0
         c-21.038,0-38.25,17.212-38.25,38.25v439.875c0,21.037,17.212,38.25,38.25,38.25h286.875c21.037,0,38.25-17.213,38.25-38.25
         c21.037,0,38.25-17.213,38.25-38.25c21.037,0,38.25-17.213,38.25-38.25V143.438v-9.562L401.625,0z M401.625,28.688l89.888,105.188
         H420.75c-9.562,0-19.125-9.562-19.125-19.125V28.688z M401.625,573.75H114.75c-9.562,0-19.125-7.65-19.125-19.125V114.75
         c0-9.562,7.65-19.125,19.125-19.125l0,0v420.75c0,21.037,17.212,38.25,38.25,38.25h267.75
         C420.75,564.188,413.1,573.75,401.625,573.75z M459,516.375c0,9.562-7.65,19.125-19.125,19.125H153
         c-9.562,0-19.125-7.65-19.125-19.125V76.5c0-9.562,7.65-19.125,19.125-19.125l0,0v420.75c0,21.037,17.212,38.25,38.25,38.25H459
         L459,516.375z M497.25,478.125c0,9.562-7.65,19.125-19.125,19.125H191.25c-9.562,0-19.125-7.65-19.125-19.125V38.25
         c0-9.562,7.65-19.125,19.125-19.125H382.5v95.625c0,21.038,17.213,38.25,38.25,38.25h76.5V478.125z"/>
    </g>
  </SvgIcon>
);

DocumentSheetsIcon.defaultProps = {
  viewBox: "0 0 600 600"
}

export default DocumentSheetsIcon;
