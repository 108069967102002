import { SvgIcon } from '@mui/material';

const PlusIcon = ({ fillColor = '#59CB7E', ...props }) => (
  <SvgIcon {...props}>
    <g id="Landing-page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Invoice-Step-2-popoups" transform="translate(-154.000000, -716.000000)" fill={fillColor} fillRule="nonzero">
        <g id="Group-21" transform="translate(133.000000, 707.000000)">
          <g id="Group-18" transform="translate(8.000000, 0.000000)">
            <g id="Group-11">
              <path d="M21.7115203,16.5 L25.8242683,12.3868056 C25.9373984,12.2735788 25.9998211,12.1225202 26,11.9614446 C26,11.8002797 25.9375772,11.6490422 25.8242683,11.5359942 L25.4638618,11.1756537 C25.3505528,11.0621585 25.1995041,11 25.0382602,11 C24.8772846,11 24.7262358,11.0621585 24.6129268,11.1756537 L20.5001789,15.2885797 L16.387252,11.1756537 C16.274122,11.0621585 16.1229837,11 15.9618293,11 C15.8008537,11 15.6497154,11.0621585 15.5365854,11.1756537 L15.176,11.5359942 C14.9413333,11.7706761 14.9413333,12.152392 15.176,12.3868056 L19.2888374,16.5 L15.176,20.6130155 C15.0627805,20.7264212 15.0004472,20.8774798 15.0004472,21.0385554 C15.0004472,21.1996309 15.0627805,21.3506895 15.176,21.4640058 L15.5364959,21.8243463 C15.649626,21.937752 15.8008537,22 15.9617398,22 C16.1228943,22 16.2740325,21.937752 16.3871626,21.8243463 L20.5000894,17.7113308 L24.6128374,21.8243463 C24.7261463,21.937752 24.8771951,22 25.0381707,22 L25.0383496,22 C25.1994146,22 25.3504634,21.937752 25.4637724,21.8243463 L25.8241789,21.4640058 C25.9373089,21.3507789 25.9997317,21.1996309 25.9997317,21.0385554 C25.9997317,20.8774798 25.9373089,20.7264212 25.8241789,20.6131049 L21.7115203,16.5 Z" id="Path" transform="translate(20.500000, 16.500000) rotate(-46.000000) translate(-20.500000, -16.500000) "></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

PlusIcon.defaultProps = {
  viewBox: '0 0 15 15',
};

export default PlusIcon;
