import { SvgIcon } from '@mui/material';

const TriangleIcon = props => (
  <SvgIcon {...props}>
    <title>Triangle</title>
    <g id="Landing-page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Personal-Dashboard-" transform="translate(-1016.000000, -519.000000)" fill="#777993">
          <polygon id="Triangle" transform="translate(1019.000000, 520.500000) scale(1, -1) translate(-1019.000000, -520.500000)" points="1019 519 1022 522 1016 522"></polygon>
        </g>
    </g>
  </SvgIcon>
);

TriangleIcon.defaultProps = {
  viewBox: "0 0 6 3"
};

export default TriangleIcon;
