import { SvgIcon } from '@mui/material';

const NotepadIcon = props => (
  <SvgIcon {...props}>
		<g>
			<path d="M15.5,17h13c0.553,0,1-0.447,1-1s-0.447-1-1-1h-13c-0.553,0-1,0.447-1,1S14.947,17,15.5,17z"/>
			<path d="M15.5,48h13c0.553,0,1-0.447,1-1s-0.447-1-1-1h-13c-0.553,0-1,0.447-1,1S14.947,48,15.5,48z"/>
			<path d="M15.5,23h29c0.553,0,1-0.447,1-1s-0.447-1-1-1h-29c-0.553,0-1,0.447-1,1S14.947,23,15.5,23z"/>
			<path d="M15.5,35h29c0.553,0,1-0.447,1-1s-0.447-1-1-1h-29c-0.553,0-1,0.447-1,1S14.947,35,15.5,35z"/>
			<path d="M15.5,42h29c0.553,0,1-0.447,1-1s-0.447-1-1-1h-29c-0.553,0-1,0.447-1,1S14.947,42,15.5,42z"/>
			<path d="M15.5,29h14c0.553,0,1-0.447,1-1s-0.447-1-1-1h-14c-0.553,0-1,0.447-1,1S14.947,29,15.5,29z"/>
			<path d="M44.5,27h-7c-0.553,0-1,0.447-1,1s0.447,1,1,1h7c0.553,0,1-0.447,1-1S45.053,27,44.5,27z"/>
			<path d="M32.79,27.29C32.6,27.479,32.5,27.729,32.5,28c0,0.26,0.109,0.52,0.29,0.71C32.979,28.899,33.229,29,33.5,29
				c0.27,0,0.52-0.11,0.71-0.29c0.18-0.19,0.29-0.44,0.29-0.71c0-0.271-0.101-0.521-0.29-0.71C33.83,26.92,33.17,26.91,32.79,27.29z"
				/>
			<path d="M41.5,2h-6V1c0-0.553-0.447-1-1-1h-9c-0.553,0-1,0.447-1,1v1h-6h-13v58h49V2H41.5z M33.5,2v1v2h-7V3V2H33.5z M24.5,4v2v1h1
				h9h1V6V4h5v1v3h-21V5V4H24.5z M17.5,9v1h1h23h1V9V7h7v48h-39V7h7V9z M52.5,58h-45V4h10v1h-9v52h43V5h-9V4h10V58z"/>
		</g>
  </SvgIcon>
);

NotepadIcon.defaultProps = {
  viewBox: "0 0 60 60"
};

export default NotepadIcon;
