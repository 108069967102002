import { SvgIcon } from '@mui/material';

const NetworkingIcon = props => (
  <SvgIcon {...props}>
		<g>
			<path d="M240.061,168.121c-22.091,0-40,17.909-40,40c0.026,22.081,17.919,39.974,40,40c22.091,0,40-17.909,40-40
				S262.152,168.121,240.061,168.121z M240.061,232.121c-13.255,0-24-10.745-24-24c0.014-13.249,10.751-23.986,24-24
				c13.255,0,24,10.745,24,24S253.316,232.121,240.061,232.121z"/>
		</g>
		<g>
			<path d="M322.773,191.746l-11.051-4.875c-0.43-1.543-0.893-3.036-1.391-4.481l6.148-10.609c1.414-2.441,1.438-5.447,0.062-7.91
				c-3.232-5.773-7.092-11.171-11.512-16.094c-1.941-2.17-4.909-3.113-7.746-2.461l-9.223,2.112V96.121
				c-0.015-17.489-9.535-33.587-24.854-42.025c12.203-12.783,11.733-33.039-1.05-45.243C249.374-3.35,229.118-2.88,216.915,9.904
				c-11.805,12.366-11.805,31.827,0,44.192c-15.318,8.438-24.839,24.536-24.854,42.025v51.307l-9.223-2.112
				c-2.836-0.651-5.801,0.29-7.742,2.457c-4.419,4.923-8.281,10.319-11.516,16.09c-1.375,2.465-1.351,5.471,0.063,7.914l6.148,10.609
				c-0.492,1.43-0.951,2.904-1.375,4.422l-11.492,4.898c-2.949,1.257-4.863,4.153-4.863,7.359v18.109
				c0,3.206,1.914,6.102,4.863,7.359l11.492,4.898c0.426,1.523,0.883,2.992,1.375,4.418l-6.148,10.609
				c-1.414,2.441-1.438,5.447-0.063,7.91c3.233,5.772,7.094,11.17,11.512,16.094c1.94,2.172,4.908,3.115,7.746,2.461l11.617-2.66
				c1.141,0.894,2.314,1.76,3.52,2.598l1.125,12.324c0.261,2.843,2.016,5.331,4.606,6.531c5.93,2.775,12.156,4.867,18.559,6.234
				c2.848,0.588,5.792-0.41,7.695-2.609l8.152-9.457h3.898l8.152,9.457c1.901,2.203,4.847,3.202,7.695,2.609
				c6.401-1.369,12.626-3.46,18.555-6.234c2.589-1.198,4.343-3.686,4.602-6.527l1.129-12.328c1.207-0.836,2.381-1.702,3.523-2.598
				l11.617,2.66c2.838,0.653,5.806-0.29,7.746-2.461c4.416-4.923,8.276-10.318,11.512-16.086c1.375-2.465,1.351-5.471-0.062-7.914
				l-6.148-10.609c0.5-1.449,0.964-2.944,1.391-4.484l11.051-4.875c2.898-1.281,4.768-4.151,4.77-7.32v-18.11
				C327.541,195.897,325.671,193.027,322.773,191.746z M240.061,16.121c8.837,0,16,7.163,16,16s-7.163,16-16,16s-16-7.163-16-16
				C224.07,23.288,231.228,16.13,240.061,16.121z M208.061,96.121c0-17.673,14.327-32,32-32c17.673,0,32,14.327,32,32v28.663
				c-4.593-1.912-9.346-3.416-14.203-4.495c-2.848-0.59-5.793,0.409-7.695,2.609l-8.152,9.457h-3.898l-8.145-9.457
				c-1.904-2.201-4.85-3.2-7.699-2.609c-4.859,1.079-9.613,2.583-14.207,4.495V96.121z M311.541,211.961l-9.852,4.348
				c-2.313,1.023-4.007,3.078-4.57,5.543c-0.729,3.302-1.737,6.536-3.012,9.668c-0.951,2.302-0.776,4.915,0.473,7.07l5.691,9.816
				c-1.289,2-2.681,3.945-4.176,5.836l-10.711-2.453c-2.511-0.583-5.149,0.086-7.078,1.797c-2.491,2.19-5.163,4.165-7.988,5.902
				c-2.136,1.323-3.526,3.572-3.754,6.074l-1.047,11.426c-2.152,0.848-4.299,1.577-6.441,2.188l-7.34-8.512
				c-1.52-1.763-3.731-2.777-6.059-2.777h-11.234c-2.327,0.001-4.539,1.015-6.059,2.777l-7.34,8.516
				c-2.141-0.613-4.293-1.344-6.441-2.191l-1.043-11.422c-0.23-2.504-1.623-4.755-3.762-6.078c-2.823-1.739-5.494-3.713-7.984-5.902
				c-1.93-1.709-4.567-2.379-7.078-1.797l-10.711,2.453c-1.496-1.891-2.888-3.836-4.176-5.836l5.691-9.816
				c1.249-2.155,1.423-4.768,0.473-7.07c-1.276-3.131-2.284-6.366-3.012-9.668c-0.57-2.501-2.304-4.577-4.664-5.582l-10.277-4.379
				v-7.539l10.277-4.379c2.36-1.005,4.094-3.081,4.664-5.582c0.729-3.302,1.737-6.536,3.012-9.668c0.95-2.302,0.776-4.915-0.473-7.07
				l-5.691-9.816c1.289-2,2.68-3.945,4.172-5.836l10.715,2.453c2.511,0.572,5.144-0.096,7.078-1.797
				c2.491-2.19,5.163-4.164,7.988-5.902c2.136-1.323,3.526-3.572,3.754-6.074l1.047-11.426c2.148-0.848,4.297-1.578,6.445-2.191
				l7.332,8.516c1.521,1.763,3.734,2.777,6.062,2.777h11.234c2.327-0.001,4.539-1.015,6.059-2.777l7.34-8.516
				c2.141,0.613,4.293,1.344,6.441,2.191l1.043,11.422c0.23,2.504,1.623,4.755,3.762,6.078c2.823,1.739,5.494,3.713,7.984,5.902
				c1.933,1.702,4.567,2.371,7.078,1.797L296.096,162c1.496,1.891,2.888,3.836,4.176,5.836l-5.691,9.816
				c-1.249,2.155-1.423,4.768-0.473,7.07c1.275,3.132,2.282,6.366,3.012,9.668c0.563,2.465,2.258,4.52,4.57,5.543l9.852,4.348
				V211.961z"/>
		</g>
		<g>
			<path d="M392.061,352.121c-22.091,0-40,17.909-40,40c0,22.091,17.909,40,40,40c22.091,0,40-17.909,40-40
				C432.035,370.04,414.142,352.147,392.061,352.121z M392.061,416.121c-13.255,0-24-10.745-24-24s10.745-24,24-24s24,10.745,24,24
				C416.047,405.37,405.31,416.107,392.061,416.121z"/>
		</g>
		<g>
			<path d="M474.773,375.746l-11.051-4.875c-0.43-1.543-0.893-3.036-1.391-4.48l6.148-10.609c1.414-2.441,1.438-5.447,0.062-7.91
				c-3.232-5.773-7.092-11.171-11.512-16.094c-1.943-2.167-4.909-3.11-7.746-2.461l-9.223,2.112v-51.307
				c-0.015-17.489-9.535-33.587-24.854-42.025c12.203-12.783,11.733-33.039-1.05-45.243s-33.039-11.733-45.243,1.05
				c-11.805,12.366-11.805,31.827,0,44.192c-15.318,8.438-24.839,24.536-24.854,42.025v51.307l-9.223-2.112
				c-2.835-0.646-5.798,0.294-7.742,2.457c-4.419,4.923-8.281,10.319-11.516,16.09c-1.375,2.465-1.351,5.471,0.063,7.914
				l6.148,10.609c-0.492,1.43-0.951,2.904-1.375,4.422l-11.492,4.898c-2.949,1.257-4.863,4.153-4.863,7.359v18.109
				c0,3.206,1.914,6.102,4.863,7.359l11.492,4.898c0.426,1.523,0.883,2.992,1.375,4.418l-6.148,10.609
				c-1.414,2.441-1.438,5.447-0.063,7.91c3.233,5.772,7.094,11.17,11.512,16.094c1.938,2.174,4.908,3.118,7.746,2.461l11.617-2.66
				c1.141,0.894,2.314,1.76,3.52,2.598l1.125,12.324c0.261,2.843,2.016,5.331,4.606,6.531c5.93,2.775,12.156,4.867,18.559,6.234
				c2.849,0.595,5.796-0.405,7.695-2.609l8.152-9.457h3.898l8.152,9.457c1.901,2.203,4.847,3.202,7.695,2.609
				c6.401-1.369,12.626-3.46,18.555-6.234c2.589-1.198,4.343-3.686,4.602-6.527l1.129-12.328c1.207-0.836,2.382-1.702,3.523-2.598
				l11.617,2.66c2.838,0.659,5.809-0.285,7.746-2.461c4.416-4.923,8.276-10.318,11.512-16.086c1.375-2.465,1.351-5.471-0.062-7.914
				l-6.148-10.609c0.5-1.449,0.964-2.944,1.391-4.484l11.051-4.875c2.898-1.281,4.768-4.151,4.77-7.32v-18.11
				C479.541,379.897,477.671,377.027,474.773,375.746z M392.061,200.121c8.837,0,16,7.163,16,16s-7.163,16-16,16s-16-7.163-16-16
				C376.07,207.288,383.228,200.13,392.061,200.121z M360.061,280.121c0-17.673,14.327-32,32-32c17.673,0,32,14.327,32,32v28.663
				c-4.593-1.912-9.346-3.416-14.203-4.495c-2.848-0.586-5.791,0.411-7.695,2.609l-8.152,9.457h-3.898l-8.145-9.457
				c-1.906-2.198-4.85-3.196-7.699-2.609c-4.859,1.079-9.613,2.583-14.207,4.495V280.121z M463.542,395.961l-9.852,4.348
				c-2.313,1.023-4.007,3.078-4.57,5.543c-0.729,3.302-1.737,6.536-3.012,9.668c-0.951,2.302-0.776,4.915,0.473,7.07l5.691,9.816
				c-1.289,2-2.681,3.945-4.176,5.836l-10.711-2.453c-2.511-0.585-5.15,0.085-7.078,1.797c-2.491,2.19-5.163,4.165-7.988,5.902
				c-2.136,1.323-3.526,3.572-3.754,6.074l-1.047,11.426c-2.152,0.848-4.299,1.577-6.441,2.188l-7.34-8.512
				c-1.52-1.763-3.731-2.777-6.059-2.777h-11.234c-2.327,0.001-4.539,1.015-6.059,2.777l-7.34,8.516
				c-2.141-0.613-4.293-1.344-6.441-2.191l-1.043-11.422c-0.23-2.504-1.623-4.755-3.762-6.078c-2.823-1.739-5.494-3.713-7.984-5.902
				c-1.928-1.711-4.567-2.381-7.078-1.797l-10.711,2.453c-1.496-1.891-2.888-3.836-4.176-5.836l5.691-9.816
				c1.249-2.155,1.423-4.768,0.473-7.07c-1.276-3.131-2.284-6.366-3.012-9.668c-0.57-2.501-2.304-4.577-4.664-5.582l-10.278-4.379
				v-7.539l10.277-4.379c2.36-1.005,4.094-3.081,4.664-5.582c0.729-3.302,1.737-6.536,3.012-9.668
				c0.951-2.302,0.776-4.915-0.473-7.07l-5.691-9.816c1.289-2,2.68-3.945,4.172-5.836l10.715,2.453
				c2.511,0.577,5.146-0.092,7.078-1.797c2.491-2.19,5.163-4.164,7.988-5.902c2.136-1.323,3.526-3.572,3.754-6.074l1.047-11.426
				c2.148-0.848,4.297-1.578,6.445-2.191l7.332,8.516c1.521,1.763,3.734,2.777,6.062,2.777h11.234
				c2.327-0.001,4.539-1.015,6.059-2.777l7.34-8.516c2.141,0.613,4.293,1.344,6.441,2.191l1.043,11.422
				c0.23,2.504,1.623,4.755,3.762,6.078c2.823,1.739,5.494,3.713,7.984,5.902c1.932,1.705,4.567,2.374,7.078,1.797L448.096,346
				c1.496,1.891,2.888,3.836,4.176,5.836l-5.691,9.816c-1.249,2.155-1.423,4.768-0.473,7.07c1.275,3.132,2.282,6.366,3.012,9.668
				c0.563,2.465,2.258,4.52,4.57,5.543l9.852,4.348V395.961z"/>
		</g>
		<g>
			<path d="M88.061,352.121c-22.091,0-40,17.909-40,40c0.026,22.081,17.919,39.974,40,40c22.091,0,40-17.909,40-40
				C128.061,370.03,110.152,352.121,88.061,352.121z M88.061,416.121c-13.255,0-24-10.745-24-24c0.014-13.249,10.751-23.986,24-24
				c13.255,0,24,10.745,24,24S101.316,416.121,88.061,416.121z"/>
		</g>
		<g>
			<path d="M170.772,375.746l-11.051-4.875c-0.43-1.543-0.893-3.036-1.391-4.48l6.148-10.609c1.414-2.441,1.438-5.447,0.063-7.91
				c-3.233-5.772-7.094-11.17-11.512-16.094c-1.944-2.166-4.909-3.108-7.746-2.461l-9.223,2.112v-51.307
				c-0.015-17.489-9.535-33.587-24.854-42.025c12.203-12.783,11.733-33.039-1.05-45.243s-33.039-11.733-45.243,1.05
				c-11.805,12.366-11.805,31.827,0,44.192c-15.318,8.438-24.839,24.536-24.854,42.025v51.307l-9.223-2.112
				c-2.835-0.645-5.798,0.295-7.742,2.457c-4.419,4.923-8.281,10.319-11.516,16.09c-1.375,2.465-1.351,5.471,0.062,7.914
				l6.148,10.609c-0.5,1.449-0.964,2.944-1.391,4.484L5.35,375.746c-2.898,1.281-4.769,4.151-4.77,7.32v18.109
				c0.001,3.169,1.871,6.04,4.77,7.321l11.051,4.875c0.43,1.543,0.893,3.036,1.391,4.48l-6.148,10.609
				c-1.414,2.441-1.438,5.447-0.063,7.91c3.233,5.772,7.094,11.17,11.512,16.094c1.938,2.174,4.908,3.118,7.746,2.461l11.617-2.66
				c1.141,0.894,2.314,1.76,3.52,2.598l1.125,12.324c0.261,2.843,2.015,5.331,4.605,6.531c5.93,2.775,12.156,4.867,18.559,6.234
				c2.849,0.596,5.797-0.404,7.695-2.609l8.152-9.457h3.898l8.152,9.457c1.901,2.203,4.847,3.202,7.695,2.609
				c6.401-1.369,12.626-3.46,18.555-6.234c2.589-1.198,4.343-3.686,4.602-6.527l1.129-12.328c1.207-0.836,2.382-1.702,3.523-2.598
				l11.617,2.66c2.838,0.658,5.808-0.286,7.746-2.461c4.416-4.923,8.277-10.318,11.512-16.086c1.375-2.465,1.351-5.471-0.063-7.914
				l-6.148-10.609c0.5-1.449,0.964-2.944,1.391-4.484l11.051-4.875c2.898-1.281,4.768-4.151,4.77-7.32v-18.11
				C175.541,379.897,173.671,377.027,170.772,375.746z M88.061,200.121c8.837,0,16,7.163,16,16s-7.163,16-16,16s-16-7.163-16-16
				C72.07,207.288,79.228,200.13,88.061,200.121z M56.061,280.121c0-17.673,14.327-32,32-32s32,14.327,32,32v28.663
				c-4.593-1.912-9.346-3.416-14.203-4.495c-2.848-0.589-5.793,0.409-7.695,2.609l-8.152,9.457h-3.898l-8.145-9.457
				c-1.905-2.199-4.85-3.196-7.699-2.609c-4.859,1.079-9.613,2.583-14.207,4.495V280.121z M159.541,395.961l-9.852,4.348
				c-2.313,1.023-4.007,3.078-4.57,5.543c-0.727,3.302-1.735,6.537-3.012,9.668c-0.951,2.302-0.776,4.915,0.473,7.07l5.691,9.816
				c-1.289,2-2.681,3.945-4.176,5.836l-10.711-2.453c-2.511-0.586-5.151,0.084-7.078,1.797c-2.491,2.19-5.163,4.165-7.988,5.902
				c-2.136,1.323-3.526,3.572-3.754,6.074l-1.047,11.426c-2.152,0.848-4.299,1.577-6.441,2.188l-7.34-8.512
				c-1.52-1.763-3.731-2.777-6.059-2.777H82.443c-2.327,0.001-4.539,1.015-6.059,2.777l-7.34,8.516
				c-2.141-0.613-4.293-1.344-6.441-2.191l-1.043-11.422c-0.23-2.504-1.623-4.755-3.762-6.078c-2.823-1.739-5.494-3.713-7.984-5.902
				c-1.929-1.71-4.567-2.38-7.078-1.797l-10.711,2.453c-1.496-1.891-2.888-3.836-4.176-5.836l5.691-9.816
				c1.249-2.155,1.423-4.768,0.473-7.07c-1.276-3.131-2.284-6.366-3.012-9.668c-0.563-2.465-2.258-4.52-4.57-5.543l-9.851-4.348
				v-7.68l9.852-4.348c2.313-1.023,4.007-3.078,4.57-5.543c0.727-3.302,1.735-6.537,3.012-9.668c0.951-2.302,0.776-4.915-0.473-7.07
				l-5.691-9.816c1.289-2,2.68-3.945,4.172-5.836l10.715,2.453c2.511,0.575,5.145-0.094,7.078-1.797
				c2.491-2.19,5.163-4.164,7.988-5.902c2.136-1.323,3.526-3.572,3.754-6.074l1.047-11.426c2.148-0.848,4.297-1.578,6.445-2.191
				l7.332,8.516c1.521,1.763,3.734,2.777,6.063,2.777h11.234c2.327-0.001,4.539-1.015,6.059-2.777l7.34-8.516
				c2.141,0.613,4.293,1.344,6.441,2.191l1.043,11.422c0.23,2.504,1.623,4.755,3.762,6.078c2.823,1.739,5.494,3.713,7.984,5.902
				c1.931,1.706,4.567,2.375,7.078,1.797L144.096,346c1.496,1.891,2.888,3.836,4.176,5.836l-5.691,9.816
				c-1.249,2.155-1.423,4.768-0.473,7.07c1.277,3.131,2.284,6.366,3.012,9.668c0.563,2.465,2.258,4.52,4.57,5.543l9.852,4.348
				V395.961z"/>
		</g>
		<g>
			<path d="M155.979,65.098c-48.463,20.498-87.79,57.962-110.613,105.375l14.414,6.945c21.134-43.909,57.553-78.604,102.433-97.586
				L155.979,65.098z"/>
		</g>
		<g>
			<path d="M302.94,454.043c-39.693,12.998-82.438,13.377-122.355,1.086l-4.75,15.281c43.097,13.339,89.277,12.927,132.129-1.18
				L302.94,454.043z"/>
		</g>
		<g>
			<path d="M321.983,64.199l-6.07,14.805c46.536,19.042,84.18,54.93,105.422,100.504l14.492-6.773
				C412.89,123.518,372.237,84.762,321.983,64.199z"/>
		</g>
  </SvgIcon>
);

NetworkingIcon.defaultProps = {
  viewBox: "0 0 480.123 480.123"
};

export default NetworkingIcon;
