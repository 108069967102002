import { SvgIcon } from '@mui/material';

const GiftCardIcon = props => (
  <SvgIcon {...props}>
    <g>
			<path d="M469.333,64H42.667C19.146,64,0,83.135,0,106.667v298.667C0,428.865,19.146,448,42.667,448h426.667
				C492.854,448,512,428.865,512,405.333V106.667C512,83.135,492.854,64,469.333,64z M490.667,405.333
				c0,11.76-9.563,21.333-21.333,21.333H42.667c-11.771,0-21.333-9.573-21.333-21.333V384h469.333V405.333z M490.667,362.667H21.333
				V320h469.333V362.667z M490.667,298.667H21.333v-192c0-11.76,9.563-21.333,21.333-21.333h426.667
				c11.771,0,21.333,9.573,21.333,21.333V298.667z"/>
			<path d="M96,149.333c5.875,0,10.667,4.781,10.667,10.667c0,5.896,4.771,10.667,10.667,10.667c5.896,0,10.667-4.771,10.667-10.667
				c0-17.646-14.354-32-32-32s-32,14.354-32,32v42.667c0,17.646,14.354,32,32,32s32-14.354,32-32V192
				c0-5.896-4.771-10.667-10.667-10.667h-10.667C100.771,181.333,96,186.104,96,192s4.771,10.667,10.667,10.667
				c0,5.885-4.792,10.667-10.667,10.667s-10.667-4.781-10.667-10.667V160C85.333,154.115,90.125,149.333,96,149.333z"/>
			<path d="M298.667,128h-96c-5.896,0-10.667,4.771-10.667,10.667V224c0,5.896,4.771,10.667,10.667,10.667
				c5.896,0,10.667-4.771,10.667-10.667v-32h21.333c5.896,0,10.667-4.771,10.667-10.667c0-5.896-4.771-10.667-10.667-10.667h-21.333
				v-21.333H256V224c0,5.896,4.771,10.667,10.667,10.667c5.896,0,10.667-4.771,10.667-10.667v-74.667h21.333
				c5.896,0,10.667-4.771,10.667-10.667C309.333,132.771,304.563,128,298.667,128z"/>
			<path d="M160,128c-5.896,0-10.667,4.771-10.667,10.667V224c0,5.896,4.771,10.667,10.667,10.667s10.667-4.771,10.667-10.667
				v-85.333C170.667,132.771,165.896,128,160,128z"/>
		</g>
  </SvgIcon>
);

GiftCardIcon.defaultProps = {
  viewBox: "0 0 512 512"
};

export default GiftCardIcon;
