import { SvgIcon } from '@mui/material';

const ShoppingCartIcon = props => (
  <SvgIcon {...props}>
    <g>
      <path d="M56.806,40.576L64.26,8.63H11.512l-0.582-8H0v2h9.07l3.932,54h0.002c0.04,4,3.161,6.964,6.996,6.964
        c3.52,0,6.432-2.577,6.92-5.964h10.16c0.488,3.387,3.401,6,6.92,6c3.86,0,7-3.14,7-7s-3.14-7-7-7c-3.52,0-6.432,2.613-6.92,6H26.92
        c-0.488-3.387-3.401-6-6.92-6c-2.132,0-4.041,0.961-5.326,2.469l-0.621-8.54L56.806,40.576z M61.74,10.63l-6.546,28.054
        l-41.287,2.88l-2.25-30.934H61.74z M44,51.63c2.757,0,5,2.243,5,5s-2.243,5-5,5s-5-2.243-5-5S41.243,51.63,44,51.63z M20,51.63
        c2.757,0,5,2.243,5,5s-2.243,5-5,5s-5-2.243-5-5S17.243,51.63,20,51.63z"/>
      <polygon points="27,26.63 35,26.63 35,34.63 37,34.63 37,26.63 45,26.63 45,24.63 37,24.63 37,16.63 35,16.63 35,24.63 27,24.63"/>
    </g>
  </SvgIcon>
);

ShoppingCartIcon.defaultProps = {
  viewBox: "0 0 64.26 64.26"
};

export default ShoppingCartIcon;
