import { SvgIcon } from '@mui/material';

const CreditIcon = props => (
  <SvgIcon {...props}>
    <g id="Roster" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Desktop-HD" transform="translate(-179.000000, -184.000000)" fill="#FFA812" fillRule="nonzero">
        <g id="credit-card" transform="translate(179.000000, 184.000000)">
          <path d="M0,139.667 L0,272 C0,310.108 30.892,341 69,341 L442.334,341 C480.442,341 511.334,310.108 511.334,272 L511.334,139.667 C511.334,133.04 505.961,127.667 499.334,127.667 L12,127.667 C5.373,127.667 0,133.04 0,139.667 Z M127.667,223.667 L95.667,223.667 C84.069,223.667 74.667,214.265 74.667,202.667 C74.667,191.069 84.069,181.667 95.667,181.667 L127.667,181.667 C139.265,181.667 148.667,191.069 148.667,202.667 C148.667,214.265 139.265,223.667 127.667,223.667 Z" id="Shape"></path>
          <path d="M511.334,73.667 L511.334,69.334 C511.334,31.226 480.442,0.334 442.334,0.334 L69,0.334 C30.892,0.334 0,31.226 0,69.334 L0,73.667 C0,80.294 5.373,85.667 12,85.667 L499.334,85.667 C505.961,85.667 511.334,80.294 511.334,73.667 Z" id="Path"></path>
        </g>
      </g>
    </g>
  </SvgIcon>
);

CreditIcon.defaultProps = {
  viewBox: "0 0 512 341"
};

export default CreditIcon;
