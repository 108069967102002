import { SvgIcon } from '@mui/material';

const AnalyticsTwoIcon = props => (
  <SvgIcon {...props}>
    <g>
      <path d="M469.984,0.008c-1.826,0-7.094,0-8,0H159.981c-5.523,0-10,4.478-10,10s4.477,10,10,10h274.247
        c-3.954,6.403-6.242,13.937-6.242,21.999v130.982c0,5.522,4.477,10,10,10s10-4.478,10-10v-30.985h53.998c5.523,0,10-4.478,10-10
        V42.007C511.983,18.849,493.143,0.008,469.984,0.008z M491.984,122.004h-43.999V42.007c0-12.131,9.869-21.999,21.999-21.999
        s21.999,9.869,21.999,21.999V122.004z"/>
    </g>
    <g>
      <path d="M501.072,407.524l-65.32-65.32c7.538-15.374,11.778-32.645,11.778-50.89c0-63.961-52.036-115.996-115.996-115.996
        s-115.996,52.035-115.996,115.996c0,14.623,2.727,28.62,7.688,41.518l-11.705,11.705l-48.143-48.144
        c-1.875-1.875-4.419-2.929-7.071-2.929c-2.652,0-5.196,1.054-7.071,2.929l-11.255,11.255v-91.312c0-5.522-4.477-10-10-10
        c-5.523,0-10,4.478-10,10v111.312l-90.415,90.416c-3.905,3.905-3.905,10.237,0,14.143c1.953,1.952,4.512,2.929,7.071,2.929
        s5.119-0.977,7.071-2.929l76.273-76.273v72.064H73.989c-5.523,0-10,4.478-10,10v31.999c0,23.158,18.84,41.999,41.999,41.999
        h299.998c23.159,0,42-18.841,42-42.001v-8.585c6.996,6.791,16.218,10.525,26.067,10.525c0.062,0,0.125-0.001,0.188-0.001
        c10.072-0.049,19.533-3.991,26.643-11.101C515.632,446.082,515.717,422.169,501.072,407.524z M331.533,195.317
        c52.933,0,95.997,43.064,95.997,95.997s-43.064,95.997-95.997,95.997c-36.348,0-68.041-20.306-84.333-50.169l57.79-57.79v22.687
        c0,5.522,4.477,10,10,10s10-4.478,10-10v-47.992c0-5.522-4.477-10-10-10h-47.993c-5.523,0-10,4.478-10,10c0,5.522,4.477,10,10,10
        h25.015l-52.974,52.974c-2.278-8.185-3.501-16.805-3.501-25.706C235.536,238.38,278.6,195.317,331.533,195.317z M105.987,491.993
        c-12.131-0.001-21.999-9.87-21.999-22v-21.999h279.997v12c0,0.327,0.018,0.65,0.049,0.969c-0.031,0.319-0.049,0.642-0.049,0.969
        v8.061c0,8.063,2.288,15.598,6.243,22.001H105.987z M427.986,469.99c0,12.133-9.87,22.002-22,22.002
        c-12.132,0-22.001-9.87-22.001-22.001v-8.061c0-0.327-0.018-0.65-0.049-0.969c0.031-0.319,0.049-0.642,0.049-0.969v-21.999
        c0-5.522-4.477-10-10-10H137.981V335.93l18.325-18.325l48.143,48.144c1.875,1.875,4.419,2.929,7.071,2.929
        c2.652,0,5.196-1.054,7.071-2.929l13.982-13.982c20.41,33.288,57.134,55.542,98.96,55.542c18.157,0,35.352-4.198,50.669-11.668
        l45.784,45.784V469.99z M486.742,446.687c-3.358,3.358-7.833,5.221-12.599,5.243c-0.029,0.001-0.059,0.001-0.089,0.001
        c-4.696,0-9.074-1.794-12.335-5.056l-61.876-61.876c9.646-7.052,18.165-15.555,25.237-25.185l61.85,61.85
        C493.777,428.512,493.693,439.738,486.742,446.687z"/>
    </g>
    <g>
      <path d="M394.846,281.315c-5.523,0-10,4.478-10,10c0,29.396-23.916,53.312-53.313,53.312c-5.523,0-10,4.477-10,10
        c0,5.522,4.477,10,10,10c40.425,0,73.313-32.888,73.313-73.312C404.846,285.793,400.369,281.315,394.846,281.315z"/>
    </g>
    <g>
      <path d="M256.118,172.003h-9.127v-66.998c0-5.522-4.477-10-10-10h-45.922c-5.523,0-10,4.478-10,10v66.998h-16.076V64.006
        c0-5.522-4.477-10-10-10h-43.999c-5.523,0-10,4.478-10,10v107.996H84.997v-43.999c0-5.522-4.477-10-10-10H30.999
        c-5.523,0-10,4.478-10,10v43.999H10c-5.523,0-10,4.478-10,10c0,5.522,4.477,10,10,10h246.119c5.523,0,10-4.478,10-10
        C266.118,176.48,261.641,172.003,256.118,172.003z M64.998,172.003H40.999v-33.999h23.999V172.003z M144.995,172.003h-23.999
        V74.006h23.999V172.003z M226.992,172.003H201.07v-56.998h25.922V172.003z"/>
    </g>
    <g>
      <path d="M337.451,54.006h-77.459c-5.523,0-10,4.478-10,10c0,5.522,4.477,10,10,10h77.459c5.523,0,10-4.478,10-10
        C347.45,58.484,342.974,54.006,337.451,54.006z"/>
    </g>
    <g>
      <path d="M393.987,56.935c-1.86-1.86-4.44-2.93-7.07-2.93c-2.63,0-5.21,1.069-7.07,2.93c-1.86,1.861-2.93,4.44-2.93,7.07
        c0,2.63,1.07,5.21,2.93,7.069c1.86,1.86,4.44,2.931,7.07,2.931c2.63,0,5.21-1.07,7.07-2.931c1.86-1.859,2.93-4.439,2.93-7.069
        C396.917,61.376,395.847,58.796,393.987,56.935z"/>
    </g>
    <g>
      <path d="M298.721,98.005h-16.397c-5.523,0-10,4.478-10,10c0,5.522,4.477,10,10,10h16.397c5.523,0,10-4.478,10-10
        C308.721,102.483,304.244,98.005,298.721,98.005z"/>
    </g>
    <g>
      <path d="M386.915,98.005h-49.464c-5.523,0-10,4.478-10,10c0,5.522,4.477,10,10,10h49.464c5.523,0,10-4.478,10-10
        C396.915,102.483,392.438,98.005,386.915,98.005z"/>
    </g>
    <g>
      <path d="M17.079,442.694c-1.86-1.86-4.44-2.931-7.07-2.931c-2.64,0-5.21,1.07-7.07,2.931c-1.87,1.859-2.93,4.439-2.93,7.069
        c0,2.63,1.06,5.21,2.93,7.07c1.86,1.86,4.43,2.93,7.07,2.93c2.63,0,5.2-1.069,7.07-2.93c1.86-1.86,2.93-4.44,2.93-7.07
        C20.009,447.132,18.939,444.552,17.079,442.694z"/>
    </g>
  </SvgIcon>
);

AnalyticsTwoIcon.defaultProps = {
  viewBox: "0 0 512 512"
};

export default AnalyticsTwoIcon;
