import { SvgIcon } from '@mui/material';

const MegaphoneIcon = props => (
  <SvgIcon {...props}>
		<g>
			<path d="M497,241h-45c-8.284,0-15,6.716-15,15s6.716,15,15,15h45c8.284,0,15-6.716,15-15S505.284,241,497,241z"/>
		</g>
		<g>
			<path d="M477.607,335.394l-30-30c-5.857-5.857-15.355-5.857-21.213,0c-5.859,5.857-5.859,15.355,0,21.213l30,30
				c5.857,5.858,15.356,5.858,21.213,0C483.465,350.749,483.465,341.252,477.607,335.394z"/>
		</g>
		<g>
			<path d="M477.606,155.394c-5.857-5.857-15.355-5.857-21.213,0l-30,30c-5.858,5.858-5.858,15.355,0,21.213
				c5.858,5.858,15.356,5.857,21.213,0l30-30C483.464,170.749,483.464,161.252,477.606,155.394z"/>
		</g>
		<g>
			<path d="M347,61c-22.531,0-41.245,16.645-44.497,38.284l-13.683,13.683C265.005,136.782,229.933,151,195,151h-90
				c-19.555,0-36.228,12.541-42.42,30H60c-33.084,0-60,26.916-60,60s26.916,60,60,60h2.58c4.527,12.764,14.656,22.893,27.42,27.42
				V406c0,24.813,20.188,45,45.001,45C159.813,451,180,430.813,180,406v-75h15c34.932,0,70.004,14.218,93.82,38.033l13.683,13.683
				C305.755,404.354,324.47,421,347.001,421C371.813,421,392,400.813,392,376V106C392,81.187,371.813,61,347,61z M60,271
				c-16.542,0-30-13.458-30-30s13.458-30,30-30V271z M150,406c0,8.271-6.729,15-15,15s-15-6.729-15-15v-75h30V406z M180,301h-75
				c-8.271,0-15-6.729-15-15v-90c0-8.271,6.729-15,15-15h75V301z M302,340.346c-25.341-21.932-57.922-35.559-92.1-38.666
				l0.001-121.361c34.177-3.106,66.758-16.733,92.099-38.666V340.346z M362,376c0,8.271-6.729,15-14.999,15H347
				c-8.271,0-15-6.729-15-15V106c0-8.271,6.729-15,15.001-15c8.27,0,14.999,6.729,14.999,15V376z"/>
		</g>
  </SvgIcon>
);

MegaphoneIcon.defaultProps = {
  viewBox: "0 0 512 512"
};

export default MegaphoneIcon;
