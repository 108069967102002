import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';

import { TickIcon } from './index';

const useStyles = makeStyles()((theme) => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.spacing(9/4),
    width: theme.spacing(9/4),
    borderRadius: theme.spacing(1/4),
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: grey[500],
    backgroundColor: '#ffffff',
  },
  error: {
    borderColor: theme.palette.error.main,
    borderWidth: 2,
  },
  tickIcon: {
    fontSize: theme.spacing(3/2),
    padding: theme.spacing(1/8),
  },
}));

const UncheckedCheckboxIcon = ({ children, error }) => {
  const {classes, cx} = useStyles();

  return (
    <Grid
      container
      className={cx(
        classes.root,
        error ? classes.error : '',
      )}
    >
      {children}
    </Grid>
  );
};

const CheckedCheckboxIcon = ({ error, disabled }) => {
  const {classes} = useStyles();
  const theme = useTheme();

  const strokeColor = disabled ? 'rgba(0, 0, 0, 0.38)' : theme.palette.primary.main;

  return (
    <UncheckedCheckboxIcon error={error}>
      <TickIcon
        className={classes.tickIcon}
        strokeColor={strokeColor}
      />
    </UncheckedCheckboxIcon>
  );
};

export { UncheckedCheckboxIcon, CheckedCheckboxIcon };
